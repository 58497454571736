/*///////////////////////////////////
// Bootstrap Override
///////////////////////////////////*/
body {
    position: relative;
    min-height: 100%;
    scroll-behavior: smooth;
    font-family: 'Quicksand', sans-serif;
  }

  * {
    outline: none !important;
  }

  img {
    max-width: 100%;
  }

  a {
    color: #007AFF;
  }

  a:hover, a:focus {
    color: #0062cc;
    -webkit-text-decoration-color: currentColor;
    text-decoration-color: currentColor;
  }

  p.lead {
    font-weight: inherit;
  }

  .container {
    z-index: 1;
  }

  .card {
    border-color: #f2f2f2;
    border-radius: 3px;
  }

  .card-img-top {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    max-width: 55%;
  }

  .card-block {
    padding: 2.5rem;
  }

  .card-header, .card-footer {
    font-family: 'Quicksand', sans-serif;
    background-color: #fafafa;
    padding: 1.4rem 2.5rem;
  }

  .card-header {
    border-bottom-color: #f2f2f2;
  }

  .card-footer {
    border-top-color: #f2f2f2;
  }

  .card-header:first-child {
    border-radius: 3px 3px 0 0;
  }

  .card-footer:last-child {
    border-radius: 0 0 3px 3px;
  }

  .list-group-item {
    padding: 0.4rem 1.4rem;
    border-color: #f2f2f2;
  }

  .card > .list-group:first-child .list-group-item:first-child {
    border-radius: 3px 3px 0 0;
  }

  .card-outline-black {
    border-color: black;
  }

  .card-outline-1 {
    border-color: #141414;
  }

  .card-outline-2 {
    border-color: #2b2b2b;
  }

  .card-outline-3 {
    border-color: #404040;
  }

  .card-outline-4 {
    border-color: #555555;
  }

  .card-outline-5 {
    border-color: #6a6a6a;
  }

  .card-outline-6 {
    border-color: #7F7F7F;
  }

  .card-outline-7 {
    border-color: #949494;
  }

  .card-outline-8 {
    border-color: #bebebe;
  }

  .card-outline-9 {
    border-color: #e1e1e1;
  }

  .card-outline-10 {
    border-color: #f2f2f2;
  }

  .card-outline-11 {
    border-color: #fafafa;
  }

  .card-outline-white {
    border-color: white;
  }

  .card-outline-primary {
    border-color: #007AFF;
  }

  .card-outline-info {
    border-color: #5AC8FA;
  }

  .card-outline-success {
    border-color: #4CD964;
  }

  .card-outline-warning {
    border-color: #FF9500;
  }

  .card-outline-danger {
    border-color: #FF3B30;
  }

  .card-outline-facebook {
    border-color: #3b5998;
  }

  .card-outline-twitter {
    border-color: #1da1f2;
  }

  .card-outline-google-plus {
    border-color: #db4437;
  }

  .card-outline-github {
    border-color: #24292e;
  }

  .card-outline-pinterest {
    border-color: #bd081d;
  }

  .card-outline-instagram {
    border-color: #c53f70;
  }

  .card-columns {
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
  }

  @media (max-width: 61.99em) {
    .card-columns {
      -webkit-column-count: 1;
      column-count: 1;
    }
  }

  @media (min-width: 62em) {
    .card-columns {
      -webkit-column-count: 2;
      column-count: 2;
    }
  }

  @media (min-width: 75em) {
    .card-columns {
      -webkit-column-count: 3;
      column-count: 3;
    }
  }

  .badge {
    font-family: 'Quicksand', sans-serif;
  }

  .badge:not(.badge-pill) {
    border-radius: 3px;
  }

  .btn[class*='btn-']:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .pagination .page-item .page-link {
    color: #007AFF;
  }

  .pagination .page-item.active .page-link {
    background-color: #007AFF;
    border-color: transparent;
    color: white;
  }

  .pagination-warning .page-item .page-link {
    color: #FF9500;
  }

  .pagination-warning .page-item.active .page-link {
    background-color: #FF9500;
    border-color: transparent;
    color: white;
  }

  /*///////////////////////////////////
  // Navigation
  ///////////////////////////////////*/
  .znav-container:not([class*="sticky-"]) {
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    z-index: 1030;
  }

  @media (max-width: 61.99em) {
    .znav-container {
      max-height: 100vh;
      overflow-y: auto;
    }
  }

  .znav-container hr {
    margin: 2px 0;
  }

  .znav-container .navbar {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.01em;
    border-radius: 3px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }

  .znav-container .navbar a.navbar-brand {
    font-size: 1.25rem;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-transition: color;
    -o-transition: color;
    transition: color;
  }

  .znav-container .navbar .navbar-toggler {
    padding: 0;
  }

  .znav-container ul {
    list-style-type: none;
    list-style-image: none;
    padding-left: 0;
  }

  @media (min-width: 62em) {
    .znav-container ul {
      margin-left: -1.4rem;
    }
  }

  .znav-container ul li {
    padding: 0;
    position: relative;
  }

  .znav-container ul li a:not(.btn) {
    display: block;
    padding: 0.4rem 1.4rem;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
  }

  .znav-container ul li a:not(.btn):active, .znav-container ul li a:not(.btn):hover, .znav-container ul li a:not(.btn):focus, .znav-container ul li a:not(.btn):active:focus {
    text-decoration: none;
  }

  @media (max-width: 61.99em) {
    .znav-container ul li a:not(.btn) {
      margin-left: -1.4rem;
    }
  }

  .znav-container .dropdown, .znav-container .megamenu {
    border-radius: 3px;
  }

  @media (max-width: 61.99em) {
    .znav-container .dropdown, .znav-container .megamenu {
      display: none;
    }
  }

  @media (min-width: 62em) {
    .znav-container .dropdown, .znav-container .megamenu {
      -webkit-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      pointer-events: none;
      opacity: 0;
      display: none;
    }
  }

  .znav-container .megamenu ul li a {
    font-weight: 400;
    padding: 0.2rem 1.4rem;
  }

  .znav-container .has-dropdown > a {
    position: relative;
  }

  .znav-container .has-dropdown > a:after {
    content: '';
    border-right: 2px solid currentColor;
    border-bottom: 2px solid currentColor;
    position: absolute;
    right: 0;
    top: 50%;
    display: block;
    padding: 0;
    height: 6px;
    width: 6px;
    pointer-events: none;
    z-index: 2000;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -webkit-transform: translate3d(-10px, 7px, 0) rotate(45deg);
    transform: translate3d(-10px, 7px, 0) rotate(45deg);
  }

  @media (min-width: 62em) {
    .znav-container .has-dropdown > a:after {
      -webkit-transform: translate3d(-5px, 5px, 0) rotate(45deg);
      transform: translate3d(-5px, 5px, 0) rotate(45deg);
    }
  }

  .znav-container .has-dropdown > a:after {
    top: 6px;
  }

  @media (max-width: 61.99em) {
    .znav-container .has-dropdown > a:after {
      right: 17px;
    }
  }

  @media (max-width: 61.99em) {
    .znav-container .has-dropdown .has-dropdown > a:after {
      right: -1px;
    }
  }

  .znav-container .has-dropdown .dropdown {
    left: 0;
    min-width: 160px;
    padding: 0.4rem 0;
  }

  .znav-container .has-megamenu > a {
    position: relative;
  }

  .znav-container .has-megamenu > a:after {
    content: '';
    border-right: 2px solid currentColor;
    border-bottom: 2px solid currentColor;
    position: absolute;
    right: 0;
    top: 50%;
    display: block;
    padding: 0;
    height: 6px;
    width: 6px;
    pointer-events: none;
    z-index: 2000;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -webkit-transform: translate3d(-10px, 7px, 0) rotate(45deg);
    transform: translate3d(-10px, 7px, 0) rotate(45deg);
  }

  @media (min-width: 62em) {
    .znav-container .has-megamenu > a:after {
      -webkit-transform: translate3d(-5px, 5px, 0) rotate(45deg);
      transform: translate3d(-5px, 5px, 0) rotate(45deg);
    }
  }

  .znav-container .has-megamenu > a:after {
    top: 6px;
  }

  @media (max-width: 61.99em) {
    .znav-container .has-megamenu > a:after {
      right: 17px;
    }
  }

  @media (max-width: 61.99em) {
    .znav-container ul.navbar-nav {
      padding: 1.4rem 0;
    }
  }

  @media (max-width: 61.99em) {
    .znav-container ul.navbar-nav > li .btn {
      width: 100%;
      margin: 5px 0;
    }
  }

  @media (max-width: 61.99em) {
    .znav-container ul.navbar-nav > li.has-dropdown ul.dropdown {
      padding-left: 2.5rem;
      margin-right: 1.4rem;
    }
    .znav-container ul.navbar-nav > li.has-dropdown ul.dropdown .has-dropdown > a:after {
      top: 0;
    }
    .znav-container ul.navbar-nav .z-active > .megamenu, .znav-container ul.navbar-nav .z-active > .dropdown {
      display: block;
    }
    .znav-container ul.navbar-nav > li.has-megamenu .megamenu {
      padding-left: 2.5rem;
    }
    .znav-container ul.navbar-nav .mt-sm-down-1 {
      margin-top: 1.4rem !important;
    }
  }

  @media (min-width: 62em) {
    .znav-container ul.navbar-nav li.has-dropdown .has-dropdown > a:after {
      border-top: 2px solid currentColor;
      border-bottom: 0;
      top: 8px;
      right: 15px;
    }
    .znav-container ul.navbar-nav li.has-dropdown .has-dropdown:active > a:after, .znav-container ul.navbar-nav li.has-dropdown .has-dropdown:hover > a:after, .znav-container ul.navbar-nav li.has-dropdown .has-dropdown:focus > a:after, .znav-container ul.navbar-nav li.has-dropdown .has-dropdown:active:focus > a:after {
      -webkit-transform: translate3d(2px, 5px, 0) rotate(45deg);
      transform: translate3d(2px, 5px, 0) rotate(45deg);
    }
    .znav-container ul.navbar-nav li.has-dropdown ul.dropdown {
      display: block;
      position: absolute;
      margin-left: 0;
      padding: 1.4rem 0;
    }
    .znav-container ul.navbar-nav li.has-dropdown > ul.dropdown ul.dropdown {
      left: 100%;
      top: -0.2rem;
    }
    .znav-container ul.navbar-nav li.has-dropdown > ul.dropdown ul.dropdown.dropdown-push-left {
      left: auto;
      right: 100%;
    }
    .znav-container ul.navbar-nav li.has-dropdown > ul.dropdown.dropdown-align-right {
      left: auto;
      right: 0;
    }
    .znav-container ul.navbar-nav li.has-dropdown:active > ul.dropdown, .znav-container ul.navbar-nav li.has-dropdown:hover > ul.dropdown, .znav-container ul.navbar-nav li.has-dropdown:focus > ul.dropdown, .znav-container ul.navbar-nav li.has-dropdown:active:focus > ul.dropdown {
      pointer-events: all;
      opacity: 1;
    }
    .znav-container ul.navbar-nav li.has-dropdown ul.dropdown.opened {
      opacity: 1;
      pointer-events: all;
    }
    .znav-container ul.navbar-nav > li.has-megamenu {
      position: static;
    }
    .znav-container ul.navbar-nav > li.has-megamenu .megamenu {
      display: block;
      border-radius: 3px;
      left: 0;
      position: absolute;
    }
    .znav-container ul.navbar-nav > li.has-megamenu .megamenu .gutter-0 {
      padding: 0;
    }
    .znav-container ul.navbar-nav > li.has-megamenu:active .megamenu, .znav-container ul.navbar-nav > li.has-megamenu:hover .megamenu, .znav-container ul.navbar-nav > li.has-megamenu:focus .megamenu, .znav-container ul.navbar-nav > li.has-megamenu:active:focus .megamenu {
      pointer-events: all;
      opacity: 1;
    }
  }

  .znav-container {
    background-color: #141414;
  }

  .znav-container hr {
    border-top-color: rgba(250, 250, 250, 0.05);
  }

  .znav-container button {
    color: #fafafa;
  }

  .znav-container a:not(.btn) {
    color: rgba(250, 250, 250, 0.6);
  }

  .znav-container a:not(.btn):active, .znav-container a:not(.btn):hover, .znav-container a:not(.btn):focus, .znav-container a:not(.btn):active:focus {
    color: #fafafa;
  }

  .znav-container a.navbar-brand {
    color: #fafafa;
  }

  .znav-container [class*='has-']:after {
    color: #fafafa;
  }

  .znav-container .megamenu, .znav-container .dropdown {
    color: #fafafa;
    background-color: #141414;
  }

  @media (min-width: 62em) {
    .znav-container .megamenu, .znav-container .dropdown {
      -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
    }
  }

  .znav-primary {
    background-color: #007AFF;
  }

  .znav-primary hr {
    border-top-color: rgba(250, 250, 250, 0.05);
  }

  .znav-primary button {
    color: #fafafa;
  }

  .znav-primary a:not(.btn) {
    color: rgba(250, 250, 250, 0.6);
  }

  .znav-primary a:not(.btn):active, .znav-primary a:not(.btn):hover, .znav-primary a:not(.btn):focus, .znav-primary a:not(.btn):active:focus {
    color: #fafafa;
  }

  .znav-primary a.navbar-brand {
    color: #fafafa;
  }

  .znav-primary [class*='has-']:after {
    color: #fafafa;
  }

  .znav-primary .megamenu, .znav-primary .dropdown {
    color: #fafafa;
    background-color: #007AFF;
  }

  @media (min-width: 62em) {
    .znav-primary .megamenu, .znav-primary .dropdown {
      -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
    }
  }

  .znav-success {
    background-color: #4CD964;
  }

  .znav-success hr {
    border-top-color: rgba(250, 250, 250, 0.05);
  }

  .znav-success button {
    color: #fafafa;
  }

  .znav-success a:not(.btn) {
    color: rgba(250, 250, 250, 0.6);
  }

  .znav-success a:not(.btn):active, .znav-success a:not(.btn):hover, .znav-success a:not(.btn):focus, .znav-success a:not(.btn):active:focus {
    color: #fafafa;
  }

  .znav-success a.navbar-brand {
    color: #fafafa;
  }

  .znav-success [class*='has-']:after {
    color: #fafafa;
  }

  .znav-success .megamenu, .znav-success .dropdown {
    color: #fafafa;
    background-color: #4CD964;
  }

  @media (min-width: 62em) {
    .znav-success .megamenu, .znav-success .dropdown {
      -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
    }
  }

  .znav-info {
    background-color: #5AC8FA;
  }

  .znav-info hr {
    border-top-color: rgba(250, 250, 250, 0.05);
  }

  .znav-info button {
    color: #fafafa;
  }

  .znav-info a:not(.btn) {
    color: rgba(250, 250, 250, 0.6);
  }

  .znav-info a:not(.btn):active, .znav-info a:not(.btn):hover, .znav-info a:not(.btn):focus, .znav-info a:not(.btn):active:focus {
    color: #fafafa;
  }

  .znav-info a.navbar-brand {
    color: #fafafa;
  }

  .znav-info [class*='has-']:after {
    color: #fafafa;
  }

  .znav-info .megamenu, .znav-info .dropdown {
    color: #fafafa;
    background-color: #5AC8FA;
  }

  @media (min-width: 62em) {
    .znav-info .megamenu, .znav-info .dropdown {
      -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
    }
  }

  .znav-warning {
    background-color: #FF9500;
  }

  .znav-warning hr {
    border-top-color: rgba(20, 20, 20, 0.05);
  }

  .znav-warning button {
    color: #141414;
  }

  .znav-warning a:not(.btn) {
    color: rgba(20, 20, 20);
  }

  .znav-warning a:not(.btn):active, .znav-warning a:not(.btn):hover, .znav-warning a:not(.btn):focus, .znav-warning a:not(.btn):active:focus {
    color: #141414;
  }

  .znav-warning a.navbar-brand {
    color: #141414;
  }

  .znav-warning [class*='has-']:after {
    color: #141414;
  }

  .znav-warning .megamenu, .znav-warning .dropdown {
    color: #141414;
    background-color: #FF9500;
  }

  @media (min-width: 62em) {
    .znav-warning .megamenu, .znav-warning .dropdown {
      -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
    }
  }

  .znav-danger {
    background-color: #FF3B30;
  }

  .znav-danger hr {
    border-top-color: rgba(250, 250, 250, 0.05);
  }

  .znav-danger button {
    color: #fafafa;
  }

  .znav-danger a:not(.btn) {
    color: rgba(250, 250, 250, 0.6);
  }

  .znav-danger a:not(.btn):active, .znav-danger a:not(.btn):hover, .znav-danger a:not(.btn):focus, .znav-danger a:not(.btn):active:focus {
    color: #fafafa;
  }

  .znav-danger a.navbar-brand {
    color: #fafafa;
  }

  .znav-danger [class*='has-']:after {
    color: #fafafa;
  }

  .znav-danger .megamenu, .znav-danger .dropdown {
    color: #fafafa;
    background-color: #FF3B30;
  }

  @media (min-width: 62em) {
    .znav-danger .megamenu, .znav-danger .dropdown {
      -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
    }
  }

  .znav-dark {
    background-color: #141414;
  }

  .znav-dark hr {
    border-top-color: rgba(250, 250, 250, 0.05);
  }

  .znav-dark button {
    color: #fafafa;
  }

  .znav-dark a:not(.btn) {
    color: rgba(250, 250, 250, 0.6);
  }

  .znav-dark a:not(.btn):active, .znav-dark a:not(.btn):hover, .znav-dark a:not(.btn):focus, .znav-dark a:not(.btn):active:focus {
    color: #fafafa;
  }

  .znav-dark a.navbar-brand {
    color: #fafafa;
  }

  .znav-dark [class*='has-']:after {
    color: #fafafa;
  }

  .znav-dark .megamenu, .znav-dark .dropdown {
    color: #fafafa;
    background-color: #141414;
  }

  @media (min-width: 62em) {
    .znav-dark .megamenu, .znav-dark .dropdown {
      -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
    }
  }

  .znav-white {
    background-color: white;
  }

  .znav-white hr {
    border-top-color: rgba(20, 20, 20, 0.05);
  }

  .znav-white button {
    color: #141414;
  }

  .znav-white a:not(.btn) {
    color: rgba(20, 20, 20);
  }

  .znav-white a:not(.btn):active, .znav-white a:not(.btn):hover, .znav-white a:not(.btn):focus, .znav-white a:not(.btn):active:focus {
    color: #141414;
  }

  .znav-white a.navbar-brand {
    color: #141414;
  }

  .znav-white [class*='has-']:after {
    color: #141414;
  }

  .znav-white .megamenu, .znav-white .dropdown {
    color: #141414;
    background-color: white;
  }

  @media (min-width: 62em) {
    .znav-white .megamenu, .znav-white .dropdown {
      -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
    }
  }

  @media (min-width: 62em) {
    .znav-white .dropdown, .znav-white .megamenu {
      -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
    }
  }

  .znav-semi-transparent.znav-white {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.95)), to(white));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.95) 0%, white 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0.95) 0%, white 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95) 0%, white 100%);
  }

  .znav-semi-transparent.znav-dark {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(20, 20, 20, 0.9)), to(#141414));
    background: -webkit-linear-gradient(top, rgba(20, 20, 20, 0.9) 0%, #141414 100%);
    background: -o-linear-gradient(top, rgba(20, 20, 20, 0.9) 0%, #141414 100%);
    background: linear-gradient(to bottom, rgba(20, 20, 20, 0.9) 0%, #141414 100%);
  }

  @media (max-width: 61.99em) {
    .znav-semi-transparent .dropdown, .znav-semi-transparent .megamenu {
      background-color: transparent;
    }
  }

  .znav-transparent {
    position: absolute !important;
  }

  .znav-transparent.znav-container {
    background-color: transparent;
  }

  .znav-transparent.znav-container .navbar-nav > li > a:not(.btn) {
    color: rgba(255, 255, 255, 0.8);
  }

  .navbar-toggler {
    border: 0;
    height: 50px;
    overflow: hidden;
  }

  .hamburger {
    position: relative;
    height: 50px;
    width: 50px;
  }

  .hamburger .hamburger-box {
    position: absolute;
    width: 70%;
    height: 100%;
    top: 0;
    left: 15%;
  }

  .hamburger .hamburger-box .hamburger-inner {
    margin-top: 0;
  }

  .hamburger .hamburger-box .hamburger-inner, .hamburger .hamburger-box .hamburger-inner::after, .hamburger .hamburger-box .hamburger-inner::before {
    width: 100%;
    height: 1px;
    border-radius: 3px;
    background-color: currentColor;
  }

  .hamburger.is-active .hamburger-inner {
    background-color: transparent;
  }

  .hamburger-inner::before {
    top: -7px;
  }

  .hamburger--emphatic .hamburger-inner::after {
    top: 7px;
  }

  .znav-fixed {
    position: fixed !important;
  }

  .znav-revealed {
    position: fixed;
  }

  /*///////////////////////////////////
  // Carousel
  ///////////////////////////////////*/
  .owl-theme .owl-dots {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .owl-theme .owl-dots .owl-dot {
    padding: 0.8rem 0;
  }

  .owl-theme .owl-dots .owl-dot span {
    border-radius: 3px;
    width: 50px;
    height: 2px;
    background-color: rgba(127, 127, 127, 0.35);
    -webkit-transition: background-color 0.4s ease-in-out;
    -o-transition: background-color 0.4s ease-in-out;
    transition: background-color 0.4s ease-in-out;
  }

  .owl-theme .owl-dots .owl-dot.active span {
    background-color: #007AFF;
  }

  .owl-theme .owl-dots .owl-dot:active span, .owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot:focus span, .owl-theme .owl-dots .owl-dot:active:focus span {
    background-color: #404040;
  }

  .owl-theme .owl-nav {
    margin-top: 0;
    cursor: pointer;
  }

  .owl-theme .owl-nav [class*=owl-] {
    opacity: 0;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    background-color: #f2f2f2 !important;
    font-size: 1.5625rem;
    -webkit-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #2b2b2b;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .owl-theme .owl-nav [class*=owl-]:active, .owl-theme .owl-nav [class*=owl-]:hover, .owl-theme .owl-nav [class*=owl-]:focus, .owl-theme .owl-nav [class*=owl-]:active:focus {
    color: #2b2b2b;
    opacity: 1 !important;
  }

  .owl-theme .owl-nav .owl-prev {
    left: 1.8rem;
  }

  .owl-theme .owl-nav .owl-next {
    right: 1.8rem;
  }

  .owl-theme:active .owl-nav [class*=owl-], .owl-theme:hover .owl-nav [class*=owl-], .owl-theme:focus .owl-nav [class*=owl-], .owl-theme:active:focus .owl-nav [class*=owl-] {
    opacity: 0.5;
  }

  .owl-theme.nav-square .owl-nav {
    margin-top: 0;
  }

  .owl-theme.nav-square .owl-nav [class*=owl-] {
    border-radius: 0;
  }

  .owl-theme.owl-nav-outer .owl-nav .owl-prev {
    left: -4rem;
  }

  .owl-theme.owl-nav-outer .owl-nav .owl-next {
    right: -4rem;
  }

  .owl-theme.owl-dots-inner .owl-dots {
    bottom: 0px;
  }

  .owl-theme.owl-dot-round .owl-dots .owl-dot span {
    height: 10px;
    width: 10px;
    border-radius: 5px;
  }

  .owl-theme.owl-theme-info .owl-dots .owl-dot.active span {
    background-color: #5AC8FA;
  }

  .owl-theme.owl-theme-success .owl-dots .owl-dot.active span {
    background-color: #4CD964;
  }

  .owl-theme.owl-theme-warning .owl-dots .owl-dot.active span {
    background-color: #FF9500;
  }

  .owl-theme.owl-theme-danger .owl-dots .owl-dot.active span {
    background-color: #FF3B30;
  }

  .owl-theme.owl-theme-black .owl-dots .owl-dot.active span {
    background-color: black;
  }

  .owl-theme.owl-theme-white .owl-dots .owl-dot.active span {
    background-color: white;
  }

  .owl-theme.owl-eq-items .owl-stage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .owl-theme.owl-eq-items .owl-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: auto !important;
  }

  /*///////////////////////////////////
  // Flexslider
  ///////////////////////////////////*/
  .flexslider {
    border-radius: 0;
    margin: 0;
    border: 0;
    max-width: 100%;
    overflow: hidden;
  }

  .flexslider .flex-control-thumbs li {
    width: calc(25% - 4.75px);
  }

  .flexslider .flex-control-thumbs li img {
    padding: 2px;
    border: 1px solid #bebebe;
    border-radius: 3px;
    margin-bottom: 6px;
  }

  .flexslider .flex-control-thumbs li img.flex-active {
    border-color: #404040;
  }

  .flexslider .flex-control-thumbs li:not(:nth-child(4)) {
    margin-right: 6px;
  }

  .flex-direction-nav a[class*="flex-"]:before {
    content: " ";
    height: 15px;
    width: 15px;
    border-right: 2px solid #7f7f7f;
    border-top: 2px solid #7f7f7f;
    margin: 10px;
  }

  .flex-direction-nav a.flex-next:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .flex-direction-nav a.flex-prev:before {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
  }

  /*///////////////////////////////////
  // Google-map
  ///////////////////////////////////*/
  .googlemap {
    min-height: 300px;
  }

  .googlemap > .marker-content {
    opacity: 0;
  }

  /*///////////////////////////////////
  // Hoverbox
  ///////////////////////////////////*/
  .hoverbox {
    position: relative;
    overflow: hidden;
  }

  .hoverbox .hoverbox-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    margin: 0 !important;
  }

  .hoverbox .hoverbox-primary-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
  }

  .hoverbox:active .hover-box-content-initial, .hoverbox:hover .hover-box-content-initial, .hoverbox:focus .hover-box-content-initial, .hoverbox:active:focus .hover-box-content-initial {
    opacity: 0;
  }

  .hoverbox:active .hoverbox-content, .hoverbox:hover .hoverbox-content, .hoverbox:focus .hoverbox-content, .hoverbox:active:focus .hoverbox-content {
    opacity: 1;
  }

  .hoverbox:active .as-hoverbox-content, .hoverbox:hover .as-hoverbox-content, .hoverbox:focus .as-hoverbox-content, .hoverbox:active:focus .as-hoverbox-content {
    z-index: 1;
  }

  /*///////////////////////////////////
  // Lightbox
  ///////////////////////////////////*/
  .lb-data .lb-number, .lb-data .lb-caption {
    font-family: 'Quicksand', sans-serif;
  }

  /*///////////////////////////////////
  // Remodal
  ///////////////////////////////////*/
  .remodal-video {
    padding: 0;
    min-width: 75%;
  }

  .remodal-overlay {
    background-color: rgba(20, 20, 20, 0.9);
  }

  .remodal-close {
    top: -35px;
    left: -35px;
  }

  .remodal-close:active, .remodal-close:hover, .remodal-close:focus, .remodal-close:active:focus {
    color: white;
  }

  /*///////////////////////////////////
  // Semantic-ui
  ///////////////////////////////////*/
  .ui.dropdown.selected, .ui.dropdown .menu .selected.item {
    background-color: transparent;
    color: #007AFF;
  }

  .ui.selection.dropdown {
    font-family: 'Quicksand', sans-serif;
    border-radius: 3px;
    padding: 0.8rem 2.5rem;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.01em;
    -webkit-transition: all 0.2s ease-in-out !important;
    -o-transition: all 0.2s ease-in-out !important;
    transition: all 0.2s ease-in-out !important;
    line-height: 1.5;
    z-index: 3;
  }

  .ui.selection.dropdown[class*='background-'] {
    color: #fff;
  }

  .ui.selection.dropdown[class*='background-'].visible .text:not(.default) {
    color: #fff;
  }

  .ui.selection.dropdown.visible > .text:not(.default) {
    font-weight: inherit;
    color: #141414;
  }

  .ui.selection.dropdown.active {
    border-color: #7F7F7F;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ui.selection.dropdown.active .menu {
    border-color: #7F7F7F;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ui.selection.dropdown.active:active, .ui.selection.dropdown.active:hover, .ui.selection.dropdown.active:focus, .ui.selection.dropdown.active:active:focus {
    border-color: #555555;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ui.selection.dropdown.active:active .menu, .ui.selection.dropdown.active:hover .menu, .ui.selection.dropdown.active:focus .menu, .ui.selection.dropdown.active:active:focus .menu {
    border-color: #555555;
  }

  .ui.selection.dropdown > .search.icon, .ui.selection.dropdown > .delete.icon, .ui.selection.dropdown > .dropdown.icon {
    top: 50%;
    margin: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .ui.selection.dropdown > .search.icon:before, .ui.selection.dropdown > .delete.icon:before, .ui.selection.dropdown > .dropdown.icon:before {
    content: '\f107';
    font-family: 'FontAwesome';
  }

  .ui.selection.dropdown .menu {
    border-color: #555555;
    border-radius: 0 0 3px 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0.8rem 0;
  }

  .ui.selection.dropdown .menu > .item {
    border-top: 1px solid #fff;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.01em;
    -webkit-transition: all 0.2s ease-in-out !important;
    -o-transition: all 0.2s ease-in-out !important;
    transition: all 0.2s ease-in-out !important;
    line-height: 1.5;
  }

  .ui.selection.dropdown .menu > .item:active, .ui.selection.dropdown .menu > .item:hover, .ui.selection.dropdown .menu > .item:focus, .ui.selection.dropdown .menu > .item:active:focus {
    background: #f2f2f2;
    color: #141414;
  }

  .ui.selection.dropdown:active, .ui.selection.dropdown:hover, .ui.selection.dropdown:focus, .ui.selection.dropdown:active:focus {
    border-color: #555555;
  }

  .ui.selection.dropdown:active .menu, .ui.selection.dropdown:hover .menu, .ui.selection.dropdown:focus .menu, .ui.selection.dropdown:active:focus .menu {
    border-color: #555555;
  }

  .ui.selection.dropdown.upward.visible {
    border-color: #7F7F7F;
  }

  .ui.selection.dropdown.upward.visible:active, .ui.selection.dropdown.upward.visible:hover, .ui.selection.dropdown.upward.visible:focus, .ui.selection.dropdown.upward.visible:active:focus {
    border-color: #555555;
  }

  .ui.selection.dropdown.upward.visible:active .menu, .ui.selection.dropdown.upward.visible:hover .menu, .ui.selection.dropdown.upward.visible:focus .menu, .ui.selection.dropdown.upward.visible:active:focus .menu {
    border-color: #555555;
  }

  .ui.selection.dropdown.upward.visible .menu {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 3px 3px 0 0;
  }

  .ui.selection.dropdown.upward.visible:active, .ui.selection.dropdown.upward.visible:hover, .ui.selection.dropdown.upward.visible:focus, .ui.selection.dropdown.upward.visible:active:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ui.selection.dropdown.upward.visible:active .menu, .ui.selection.dropdown.upward.visible:hover .menu, .ui.selection.dropdown.upward.visible:focus .menu, .ui.selection.dropdown.upward.visible:active:focus .menu {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ui.styled.accordion, .ui.styled.accordion .accordion {
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #e1e1e1;
  }

  .ui.styled.accordion .title, .ui.styled.accordion .accordion .title {
    color: #141414;
    border-top: 1px solid #e1e1e1;
    font-family: 'Quicksand', sans-serif;
    border-radius: 3px;
    padding: 0.8rem;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.01em;
    -webkit-transition: all 0.2s ease-in-out !important;
    -o-transition: all 0.2s ease-in-out !important;
    transition: all 0.2s ease-in-out !important;
    line-height: 1.5;
  }

  .ui.styled.accordion .title .dropdown.icon, .ui.styled.accordion .accordion .title .dropdown.icon {
    margin-right: 0.75em;
  }

  .ui.styled.accordion .title.active, .ui.styled.accordion .accordion .title.active {
    color: #141414;
  }

  .ui.styled.accordion .content, .ui.styled.accordion .accordion .content {
    padding: 0.8rem 2.5rem;
    color: #2b2b2b;
  }

  /*///////////////////////////////////
  // Typed-text
  ///////////////////////////////////*/
  .typed-cursor {
    opacity: 1;
    -webkit-animation: blink 0.8s infinite;
    animation: blink 0.8s infinite;
  }

  @-webkit-keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  span.typed-cursor {
    background: currentColor;
    width: 2px !important;
    display: inline-block;
    text-indent: 10000px;
    line-height: 1.4em;
  }

  /*///////////////////////////////////
  // Typography
  ///////////////////////////////////*/
  body {
    line-height: 1.45;
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #141414;
    font-size: 1rem;
  }

  h1, .h1 {
    font-size: 3.05176rem;
    font-family: 'Quicksand', sans-serif;
    line-height: 1;
  }

  h2, .h2 {
    font-size: 2.44141rem;
    font-family: 'Quicksand', sans-serif;
  }

  h3, .h3 {
    font-size: 1.95312rem;
    font-family: 'Quicksand', sans-serif;
  }

  h4, .h4 {
    font-size: 1.5625rem;
    font-family: 'Quicksand', sans-serif;
    margin-bottom: 1.25rem;
  }

  h5, .h5 {
    font-size: 1.25rem;
    font-family: 'Quicksand', sans-serif;
  }

  p, .p {
    font-size: 1rem;
  }

  p.lead, .p.lead {
    font-size: 1.25rem;
  }

  h6, .h6 {
    font-size: 0.8rem;
    font-family: 'Quicksand', sans-serif;
  }

  small, .small {
    font-size: 0.8rem;
  }

  .dropcap::first-letter {
    font-size: 3em;
    float: left;
    line-height: 0.92;
    margin-right: 6px;
  }

  .fs--1 {
    font-size: 0.8rem !important;
  }

  .fs-0 {
    font-size: 1rem !important;
  }

  .fs-1 {
    font-size: 1.25rem !important;
  }

  .fs-2 {
    font-size: 1.5625rem !important;
  }

  .fs-3 {
    font-size: 1.95312rem !important;
  }

  .fs-4 {
    font-size: 2.44141rem !important;
  }

  .fs-5 {
    font-size: 3.05176rem !important;
  }

  .fs-6 {
    font-size: 3.8147rem !important;
  }

  .fs-7 {
    font-size: 4.76837rem !important;
  }

  .fs-8 {
    font-size: 5.96046rem !important;
  }

  @media (min-width: 36em) {
    .fs-sm--1 {
      font-size: 0.8rem !important;
    }
    .fs-sm-0 {
      font-size: 1rem !important;
    }
    .fs-sm-1 {
      font-size: 1.25rem !important;
    }
    .fs-sm-2 {
      font-size: 1.5625rem !important;
    }
    .fs-sm-3 {
      font-size: 1.95312rem !important;
    }
    .fs-sm-4 {
      font-size: 2.44141rem !important;
    }
    .fs-sm-5 {
      font-size: 3.05176rem !important;
    }
    .fs-sm-6 {
      font-size: 3.8147rem !important;
    }
    .fs-sm-7 {
      font-size: 4.76837rem !important;
    }
    .fs-sm-8 {
      font-size: 5.96046rem !important;
    }
  }

  @media (min-width: 48em) {
    .fs-md--1 {
      font-size: 0.8rem !important;
    }
    .fs-md-0 {
      font-size: 1rem !important;
    }
    .fs-md-1 {
      font-size: 1.25rem !important;
    }
    .fs-md-2 {
      font-size: 1.5625rem !important;
    }
    .fs-md-3 {
      font-size: 1.95312rem !important;
    }
    .fs-md-4 {
      font-size: 2.44141rem !important;
    }
    .fs-md-5 {
      font-size: 3.05176rem !important;
    }
    .fs-md-6 {
      font-size: 3.8147rem !important;
    }
    .fs-md-7 {
      font-size: 4.76837rem !important;
    }
    .fs-md-8 {
      font-size: 5.96046rem !important;
    }
  }

  @media (min-width: 62em) {
    .fs-lg--1 {
      font-size: 0.8rem !important;
    }
    .fs-lg-0 {
      font-size: 1rem !important;
    }
    .fs-lg-1 {
      font-size: 1.25rem !important;
    }
    .fs-lg-2 {
      font-size: 1.5625rem !important;
    }
    .fs-lg-3 {
      font-size: 1.95312rem !important;
    }
    .fs-lg-4 {
      font-size: 2.44141rem !important;
    }
    .fs-lg-5 {
      font-size: 3.05176rem !important;
    }
    .fs-lg-6 {
      font-size: 3.8147rem !important;
    }
    .fs-lg-7 {
      font-size: 4.76837rem !important;
    }
    .fs-lg-8 {
      font-size: 5.96046rem !important;
    }
  }

  @media (min-width: 75em) {
    .fs-xl--1 {
      font-size: 0.8rem !important;
    }
    .fs-xl-0 {
      font-size: 1rem !important;
    }
    .fs-xl-1 {
      font-size: 1.25rem !important;
    }
    .fs-xl-2 {
      font-size: 1.5625rem !important;
    }
    .fs-xl-3 {
      font-size: 1.95312rem !important;
    }
    .fs-xl-4 {
      font-size: 2.44141rem !important;
    }
    .fs-xl-5 {
      font-size: 3.05176rem !important;
    }
    .fs-xl-6 {
      font-size: 3.8147rem !important;
    }
    .fs-xl-7 {
      font-size: 4.76837rem !important;
    }
    .fs-xl-8 {
      font-size: 5.96046rem !important;
    }
  }

  .font-1 {
    font-family: 'Quicksand', sans-serif;
  }

  .lh-0 {
    line-height: 0 !important;
  }

  .lh-f1 {
    line-height: 0.7 !important;
  }

  .lh-f2 {
    line-height: 0.85 !important;
  }

  .lh-1 {
    line-height: 1 !important;
  }

  .lh-2 {
    line-height: 1.15 !important;
  }

  .lh-3 {
    line-height: 1.3 !important;
  }

  .lh-4 {
    line-height: 1.45 !important;
  }

  .lh-5 {
    line-height: 1.6 !important;
  }

  .lh-6 {
    line-height: 1.75 !important;
  }

  .lh-7 {
    line-height: 1.9 !important;
  }

  .lh-8 {
    line-height: 2.05 !important;
  }

  .fw-100 {
    font-weight: 100 !important;
  }

  .fw-200 {
    font-weight: 200 !important;
  }

  .fw-300 {
    font-weight: 300 !important;
  }

  .fw-400 {
    font-weight: 400 !important;
  }

  .fw-500 {
    font-weight: 500 !important;
  }

  .fw-600 {
    font-weight: 600 !important;
  }

  .fw-700 {
    font-weight: 700 !important;
  }

  .fw-800 {
    font-weight: 800 !important;
  }

  .fw-900 {
    font-weight: 900 !important;
  }

  .text-normal {
    text-transform: none !important;
  }

  .text-smallcaps {
    font-variant: small-caps;
  }

  .ls {
    letter-spacing: 2px !important;
  }

  ul.bullet-inside {
    list-style-position: inside;
  }

  ul.bullet-none {
    list-style: none;
  }

  /*///////////////////////////////////
  // Buttons
  ///////////////////////////////////*/
  .btn {
    padding: 0.8rem 2.5rem;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.01em;
    border-radius: 3px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    line-height: 1.5;
  }

  .btn.btn-xs {
    padding: 0.2rem 0.8rem;
  }

  .btn.btn-sm {
    padding: 0.4rem 1.4rem;
    color: white;
    font-size: 14px;
  }

  .btn.btn-lg {
    padding: 1.4rem 5rem;
    font-size: 1rem;
  }

  .btn.active, .btn:active {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .btn-capsule {
    border-radius: 50px;
  }

  .btn-primary {
    color: white;
    background-color: #007AFF;
    border-color: #006ee6;
  }

  .btn-primary.active, .btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:active:focus {
    color: white;
    background-color: #0062cc;
    border-color: #0051a8;
  }

  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #007AFF;
    border-color: #006ee6;
  }

  .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
    color: white;
    background-color: #0062cc;
    background-image: none;
    border-color: #0051a8;
    z-index: 99;
  }

  .btn-info {
    color: white;
    background-color: #5AC8FA;
    border-color: #41c0f9;
  }

  .btn-info.active, .btn-info:hover, .btn-info:active, .btn-info:focus, .btn-info:active:focus {
    color: white;
    background-color: #29b8f9;
    border-color: #07abf6;
  }

  .btn-info.disabled, .btn-info:disabled {
    background-color: #5AC8FA;
    border-color: #41c0f9;
  }

  .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
    color: white;
    background-color: #29b8f9;
    background-image: none;
    border-color: #07abf6;
    z-index: 99;
  }

  .btn-success {
    color: white;
    background-color: #4CD964;
    border-color: #37d552;
  }

  .btn-success.active, .btn-success:hover, .btn-success:active, .btn-success:focus, .btn-success:active:focus {
    color: white;
    background-color: #2ac845;
    border-color: #24aa3b;
  }

  .btn-success.disabled, .btn-success:disabled {
    background-color: #4CD964;
    border-color: #37d552;
  }

  .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
    color: white;
    background-color: #2ac845;
    background-image: none;
    border-color: #24aa3b;
    z-index: 99;
  }

  .btn-warning {
    color: white;
    background-color: #FF9500;
    border-color: #e68600;
  }

  .btn-warning.active, .btn-warning:hover, .btn-warning:active, .btn-warning:focus, .btn-warning:active:focus {
    color: white;
    background-color: #cc7700;
    border-color: #a86200;
  }

  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #FF9500;
    border-color: #e68600;
  }

  .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
    color: white;
    background-color: #cc7700;
    background-image: none;
    border-color: #a86200;
    z-index: 99;
  }

  /*  LAINIE'S PRIMARY BUTTONS */
  .btn-lainies-primary {
    color: white;
    background-color: #FF3B30;
    border-color: #cf2d24;
  }

  .btn-lainies-primary.active, .btn-lainies-primary:hover, .btn-lainies-primary:active, .btn-lainies-primary:focus, .btn-lainies-primary:active:focus {
    color: white;
    background-color: #e1150a;
    border-color: #cf2d24;
  }

  .btn-lainies-primary.disabled, .btn-lainies-primary:disabled {
    background-color: #ff1c15;
    border-color: #cf2d24;
  }

  .btn-lainies-primary:active, .btn-lainies-primary.active, .show > .btn-lainies-primary.dropdown-toggle {
    color: white;
    background-color: #FF3B30;
    background-image: none;
    border-color: #cf2d24;
    z-index: 99;
  }
  /* END LAINIE'S PRIMARY BUTTONS */

  .btn-danger {
    color: white;
    background-color: #FF3B30;
    border-color: #ff2317;
  }

  .btn-danger.active, .btn-danger:hover, .btn-danger:active, .btn-danger:focus, .btn-danger:active:focus {
    color: white;
    background-color: #fc0d00;
    border-color: #d80b00;
  }

  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #FF3B30;
    border-color: #ff2317;
  }

  .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
    color: white;
    background-color: #fc0d00;
    background-image: none;
    border-color: #d80b00;
    z-index: 99;
  }

  .btn-white {
    color: #141414;
    background-color: white;
    border-color: white;
  }

  .btn-white.active, .btn-white:hover, .btn-white:active, .btn-white:focus, .btn-white:active:focus {
    color: #141414;
    background-color: #e6e6e6;
    border-color: #e0e0e0;
  }

  .btn-white.disabled, .btn-white:disabled {
    background-color: white;
    border-color: white;
  }

  .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
    color: #141414;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #e0e0e0;
    z-index: 99;
  }

  .btn-dark {
    color: white;
    background-color: #141414;
    border-color: #070707;
  }

  .btn-dark.active, .btn-dark:hover, .btn-dark:active, .btn-dark:focus, .btn-dark:active:focus {
    color: white;
    background-color: black;
    border-color: black;
  }

  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #141414;
    border-color: #070707;
  }

  .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
    color: white;
    background-color: black;
    background-image: none;
    border-color: black;
    z-index: 99;
  }

  .btn-outline-primary {
    color: #007AFF;
    background-image: none;
    background-color: transparent;
    border-color: #007AFF;
  }

  .btn-outline-primary.btn-icon span {
    border-color: #007AFF;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-outline-primary.btn-icon:hover span, .btn-outline-primary.btn-icon:focus span, .btn-outline-primary.btn-icon:active span, .btn-outline-primary.btn-icon:active:focus span {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  .btn-outline-primary:hover {
    color: white;
    background-color: #007AFF;
    border-color: #007AFF;
  }

  .btn-outline-primary:focus, .btn-outline-primary.focus {
    color: white;
    background-color: #007AFF;
    border-color: #007AFF;
  }

  .btn-outline-primary:active, .btn-outline-primary.active, .open > .btn-outline-primary.dropdown-toggle {
    color: white;
    background-color: #007AFF;
    border-color: #007AFF;
  }

  .btn-outline-primary:active:hover, .btn-outline-primary:active:focus, .btn-outline-primary:active.focus, .btn-outline-primary.active:hover, .btn-outline-primary.active:focus, .btn-outline-primary.active.focus, .open > .btn-outline-primary.dropdown-toggle:hover, .open > .btn-outline-primary.dropdown-toggle:focus, .open > .btn-outline-primary.dropdown-toggle.focus {
    color: white;
    background-color: #0051a8;
    border-color: #003d80;
  }

  .btn-outline-primary.active {
    background-color: #0051a8;
    border-color: #003d80;
  }

  .btn-outline-primary.disabled:focus, .btn-outline-primary.disabled.focus, .btn-outline-primary:disabled:focus, .btn-outline-primary:disabled.focus {
    border-color: #66afff;
  }

  .btn-outline-primary.disabled:hover, .btn-outline-primary:disabled:hover {
    border-color: #66afff;
  }

  .btn-outline-info {
    color: #5AC8FA;
    background-image: none;
    background-color: transparent;
    border-color: #5AC8FA;
  }

  .btn-outline-info.btn-icon span {
    border-color: #5AC8FA;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-outline-info.btn-icon:hover span, .btn-outline-info.btn-icon:focus span, .btn-outline-info.btn-icon:active span, .btn-outline-info.btn-icon:active:focus span {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  .btn-outline-info:hover {
    color: white;
    background-color: #5AC8FA;
    border-color: #5AC8FA;
  }

  .btn-outline-info:focus, .btn-outline-info.focus {
    color: white;
    background-color: #5AC8FA;
    border-color: #5AC8FA;
  }

  .btn-outline-info:active, .btn-outline-info.active, .open > .btn-outline-info.dropdown-toggle {
    color: white;
    background-color: #5AC8FA;
    border-color: #5AC8FA;
  }

  .btn-outline-info:active:hover, .btn-outline-info:active:focus, .btn-outline-info:active.focus, .btn-outline-info.active:hover, .btn-outline-info.active:focus, .btn-outline-info.active.focus, .open > .btn-outline-info.dropdown-toggle:hover, .open > .btn-outline-info.dropdown-toggle:focus, .open > .btn-outline-info.dropdown-toggle.focus {
    color: white;
    background-color: #07abf6;
    border-color: #0690ce;
  }

  .btn-outline-info.active {
    background-color: #07abf6;
    border-color: #0690ce;
  }

  .btn-outline-info.disabled:focus, .btn-outline-info.disabled.focus, .btn-outline-info:disabled:focus, .btn-outline-info:disabled.focus {
    border-color: #bde9fd;
  }

  .btn-outline-info.disabled:hover, .btn-outline-info:disabled:hover {
    border-color: #bde9fd;
  }

  .btn-outline-success {
    color: #4CD964;
    background-image: none;
    background-color: transparent;
    border-color: #4CD964;
  }

  .btn-outline-success.btn-icon span {
    border-color: #4CD964;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-outline-success.btn-icon:hover span, .btn-outline-success.btn-icon:focus span, .btn-outline-success.btn-icon:active span, .btn-outline-success.btn-icon:active:focus span {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  .btn-outline-success:hover {
    color: white;
    background-color: #4CD964;
    border-color: #4CD964;
  }

  .btn-outline-success:focus, .btn-outline-success.focus {
    color: white;
    background-color: #4CD964;
    border-color: #4CD964;
  }

  .btn-outline-success:active, .btn-outline-success.active, .open > .btn-outline-success.dropdown-toggle {
    color: white;
    background-color: #4CD964;
    border-color: #4CD964;
  }

  .btn-outline-success:active:hover, .btn-outline-success:active:focus, .btn-outline-success:active.focus, .btn-outline-success.active:hover, .btn-outline-success.active:focus, .btn-outline-success.active.focus, .open > .btn-outline-success.dropdown-toggle:hover, .open > .btn-outline-success.dropdown-toggle:focus, .open > .btn-outline-success.dropdown-toggle.focus {
    color: white;
    background-color: #24aa3b;
    border-color: #1d892f;
  }

  .btn-outline-success.active {
    background-color: #24aa3b;
    border-color: #1d892f;
  }

  .btn-outline-success.disabled:focus, .btn-outline-success.disabled.focus, .btn-outline-success:disabled:focus, .btn-outline-success:disabled.focus {
    border-color: #a0ebad;
  }

  .btn-outline-success.disabled:hover, .btn-outline-success:disabled:hover {
    border-color: #a0ebad;
  }

  .btn-outline-warning {
    color: #FF9500;
    background-image: none;
    background-color: transparent;
    border-color: #FF9500;
  }

  .btn-outline-warning.btn-icon span {
    border-color: #FF9500;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-outline-warning.btn-icon:hover span, .btn-outline-warning.btn-icon:focus span, .btn-outline-warning.btn-icon:active span, .btn-outline-warning.btn-icon:active:focus span {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  .btn-outline-warning:hover {
    color: white;
    background-color: #FF9500;
    border-color: #FF9500;
  }

  .btn-outline-warning:focus, .btn-outline-warning.focus {
    color: white;
    background-color: #FF9500;
    border-color: #FF9500;
  }

  .btn-outline-warning:active, .btn-outline-warning.active, .open > .btn-outline-warning.dropdown-toggle {
    color: white;
    background-color: #FF9500;
    border-color: #FF9500;
  }

  .btn-outline-warning:active:hover, .btn-outline-warning:active:focus, .btn-outline-warning:active.focus, .btn-outline-warning.active:hover, .btn-outline-warning.active:focus, .btn-outline-warning.active.focus, .open > .btn-outline-warning.dropdown-toggle:hover, .open > .btn-outline-warning.dropdown-toggle:focus, .open > .btn-outline-warning.dropdown-toggle.focus {
    color: white;
    background-color: #a86200;
    border-color: #804b00;
  }

  .btn-outline-warning.active {
    background-color: #a86200;
    border-color: #804b00;
  }

  .btn-outline-warning.disabled:focus, .btn-outline-warning.disabled.focus, .btn-outline-warning:disabled:focus, .btn-outline-warning:disabled.focus {
    border-color: #ffbf66;
  }

  .btn-outline-warning.disabled:hover, .btn-outline-warning:disabled:hover {
    border-color: #ffbf66;
  }

  .btn-outline-danger {
    color: #FF3B30;
    background-image: none;
    background-color: transparent;
    border-color: #FF3B30;
  }

  .btn-outline-danger.btn-icon span {
    border-color: #FF3B30;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-outline-danger.btn-icon:hover span, .btn-outline-danger.btn-icon:focus span, .btn-outline-danger.btn-icon:active span, .btn-outline-danger.btn-icon:active:focus span {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  .btn-outline-danger:hover {
    color: white;
    background-color: #FF3B30;
    border-color: #FF3B30;
  }

  .btn-outline-danger:focus, .btn-outline-danger.focus {
    color: white;
    background-color: #FF3B30;
    border-color: #FF3B30;
  }

  .btn-outline-danger:active, .btn-outline-danger.active, .open > .btn-outline-danger.dropdown-toggle {
    color: white;
    background-color: #FF3B30;
    border-color: #FF3B30;
  }

  .btn-outline-danger:active:hover, .btn-outline-danger:active:focus, .btn-outline-danger:active.focus, .btn-outline-danger.active:hover, .btn-outline-danger.active:focus, .btn-outline-danger.active.focus, .open > .btn-outline-danger.dropdown-toggle:hover, .open > .btn-outline-danger.dropdown-toggle:focus, .open > .btn-outline-danger.dropdown-toggle.focus {
    color: white;
    background-color: #d80b00;
    border-color: #b00900;
  }

  .btn-outline-danger.active {
    background-color: #d80b00;
    border-color: #b00900;
  }

  .btn-outline-danger.disabled:focus, .btn-outline-danger.disabled.focus, .btn-outline-danger:disabled:focus, .btn-outline-danger:disabled.focus {
    border-color: #ff9c96;
  }

  .btn-outline-danger.disabled:hover, .btn-outline-danger:disabled:hover {
    border-color: #ff9c96;
  }

  .btn-outline-dark {
    color: #141414;
    background-image: none;
    background-color: transparent;
    border-color: #141414;
  }

  .btn-outline-dark.btn-icon span {
    border-color: #141414;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-outline-dark.btn-icon:hover span, .btn-outline-dark.btn-icon:focus span, .btn-outline-dark.btn-icon:active span, .btn-outline-dark.btn-icon:active:focus span {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  .btn-outline-dark:hover {
    color: white;
    background-color: #141414;
    border-color: #141414;
  }

  .btn-outline-dark:focus, .btn-outline-dark.focus {
    color: white;
    background-color: #141414;
    border-color: #141414;
  }

  .btn-outline-dark:active, .btn-outline-dark.active, .open > .btn-outline-dark.dropdown-toggle {
    color: white;
    background-color: #141414;
    border-color: #141414;
  }

  .btn-outline-dark:active:hover, .btn-outline-dark:active:focus, .btn-outline-dark:active.focus, .btn-outline-dark.active:hover, .btn-outline-dark.active:focus, .btn-outline-dark.active.focus, .open > .btn-outline-dark.dropdown-toggle:hover, .open > .btn-outline-dark.dropdown-toggle:focus, .open > .btn-outline-dark.dropdown-toggle.focus {
    color: white;
    background-color: black;
    border-color: black;
  }

  .btn-outline-dark.active {
    background-color: black;
    border-color: black;
  }

  .btn-outline-dark.disabled:focus, .btn-outline-dark.disabled.focus, .btn-outline-dark:disabled:focus, .btn-outline-dark:disabled.focus {
    border-color: #474747;
  }

  .btn-outline-dark.disabled:hover, .btn-outline-dark:disabled:hover {
    border-color: #474747;
  }

  .btn-outline-white {
    color: white;
    background-image: none;
    background-color: transparent;
    border-color: white;
  }

  .btn-outline-white.btn-icon span {
    border-color: white;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-outline-white.btn-icon:hover span, .btn-outline-white.btn-icon:focus span, .btn-outline-white.btn-icon:active span, .btn-outline-white.btn-icon:active:focus span {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  .btn-outline-white:hover {
    color: white;
    background-color: white;
    border-color: white;
  }

  .btn-outline-white:focus, .btn-outline-white.focus {
    color: white;
    background-color: white;
    border-color: white;
  }

  .btn-outline-white:active, .btn-outline-white.active, .open > .btn-outline-white.dropdown-toggle {
    color: white;
    background-color: white;
    border-color: white;
  }

  .btn-outline-white:active:hover, .btn-outline-white:active:focus, .btn-outline-white:active.focus, .btn-outline-white.active:hover, .btn-outline-white.active:focus, .btn-outline-white.active.focus, .open > .btn-outline-white.dropdown-toggle:hover, .open > .btn-outline-white.dropdown-toggle:focus, .open > .btn-outline-white.dropdown-toggle.focus {
    color: white;
    background-color: #d4d4d4;
    border-color: #bfbfbf;
  }

  .btn-outline-white.active {
    background-color: #d4d4d4;
    border-color: #bfbfbf;
  }

  .btn-outline-white.disabled:focus, .btn-outline-white.disabled.focus, .btn-outline-white:disabled:focus, .btn-outline-white:disabled.focus {
    border-color: white;
  }

  .btn-outline-white.disabled:hover, .btn-outline-white:disabled:hover {
    border-color: white;
  }

  .btn-outline-white:hover, .btn-outline-white:active, .btn-outline-white:focus, .btn-outline-white:active:focus {
    color: #141414;
  }

  .btn-icon {
    padding-left: 3.6rem;
    position: relative;
  }

  .btn-icon.btn-icon-right {
    padding-left: 2.5rem;
    padding-right: 3.6rem;
  }

  .btn-icon span {
    width: 2.6rem;
    font-size: 1.3rem;
  }

  .btn-icon span {
    left: 0;
    top: 0;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .btn-icon.btn-icon-left span {
    left: 0;
  }

  .btn-icon.btn-icon-right span {
    right: 0;
    left: auto;
  }

  .btn-icon.btn-capsule.btn-icon-left span {
    border-radius: 50px 0 0 50px;
  }

  .btn-icon.btn-capsule.btn-icon-right span {
    border-radius: 0 50px 50px 0;
  }

  .btn-icon.btn-xs {
    padding-left: 2.32rem;
  }

  .btn-icon.btn-xs.btn-icon-right {
    padding-left: 0.8rem;
    padding-right: 2.32rem;
  }

  .btn-icon.btn-xs span {
    width: 2rem;
    font-size: 1rem;
  }

  .btn-icon.btn-sm {
    padding-left: 3.06rem;
  }

  .btn-icon.btn-sm.btn-icon-right {
    padding-left: 1.4rem;
    padding-right: 3.06rem;
  }

  .btn-icon.btn-sm span {
    width: 2.5rem;
    font-size: 1.25rem;
  }

  .btn-icon.btn-lg {
    padding-left: 6rem;
  }

  .btn-icon.btn-lg.btn-icon-right {
    padding-left: 5rem;
    padding-right: 6rem;
  }

  .btn-icon.btn-lg span {
    width: 4rem;
    font-size: 2rem;
  }

  .btn-icon[class*='btn-outline-'] span {
    border-right-width: 1px;
    border-right-style: solid;
  }

  .btn-icon[class*='btn-outline-'].btn-icon-right span {
    border-left-width: 1px;
    border-left-style: solid;
    border-right: none;
  }

  .btn-icon-pop span {
    font-size: 1.5em;
    -webkit-transform: translateY(0.13em);
    -ms-transform: translateY(0.13em);
    transform: translateY(0.13em);
    line-height: 0;
  }

  .btn-facebook {
    color: white;
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .btn-facebook.active, .btn-facebook:hover, .btn-facebook:active, .btn-facebook:focus, .btn-facebook:active:focus {
    color: white;
    background-color: #2d4373;
    border-color: #2a3f6c;
  }

  .btn-facebook.disabled, .btn-facebook:disabled {
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .btn-facebook:active, .btn-facebook.active, .show > .btn-facebook.dropdown-toggle {
    color: white;
    background-color: #2d4373;
    background-image: none;
    border-color: #2a3f6c;
    z-index: 99;
  }

  .btn-twitter {
    color: white;
    background-color: #1da1f2;
    border-color: #1da1f2;
  }

  .btn-twitter.active, .btn-twitter:hover, .btn-twitter:active, .btn-twitter:focus, .btn-twitter:active:focus {
    color: white;
    background-color: #0c85d0;
    border-color: #0b7fc6;
  }

  .btn-twitter.disabled, .btn-twitter:disabled {
    background-color: #1da1f2;
    border-color: #1da1f2;
  }

  .btn-twitter:active, .btn-twitter.active, .show > .btn-twitter.dropdown-toggle {
    color: white;
    background-color: #0c85d0;
    background-image: none;
    border-color: #0b7fc6;
    z-index: 99;
  }

  .btn-google-plus {
    color: white;
    background-color: #db4437;
    border-color: #db4437;
  }

  .btn-google-plus.active, .btn-google-plus:hover, .btn-google-plus:active, .btn-google-plus:focus, .btn-google-plus:active:focus {
    color: white;
    background-color: #bd2e22;
    border-color: #b42c20;
  }

  .btn-google-plus.disabled, .btn-google-plus:disabled {
    background-color: #db4437;
    border-color: #db4437;
  }

  .btn-google-plus:active, .btn-google-plus.active, .show > .btn-google-plus.dropdown-toggle {
    color: white;
    background-color: #bd2e22;
    background-image: none;
    border-color: #b42c20;
    z-index: 99;
  }

  .btn-github {
    color: white;
    background-color: #24292e;
    border-color: #24292e;
  }

  .btn-github.active, .btn-github:hover, .btn-github:active, .btn-github:focus, .btn-github:active:focus {
    color: white;
    background-color: #0e1011;
    border-color: #090a0c;
  }

  .btn-github.disabled, .btn-github:disabled {
    background-color: #24292e;
    border-color: #24292e;
  }

  .btn-github:active, .btn-github.active, .show > .btn-github.dropdown-toggle {
    color: white;
    background-color: #0e1011;
    background-image: none;
    border-color: #090a0c;
    z-index: 99;
  }

  .btn-outline-twitter {
    color: #1da1f2;
    background-image: none;
    background-color: transparent;
    border-color: #1da1f2;
  }

  .btn-outline-twitter.btn-icon span {
    border-color: #1da1f2;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-outline-twitter.btn-icon:hover span, .btn-outline-twitter.btn-icon:focus span, .btn-outline-twitter.btn-icon:active span, .btn-outline-twitter.btn-icon:active:focus span {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  .btn-outline-twitter:hover {
    color: white;
    background-color: #1da1f2;
    border-color: #1da1f2;
  }

  .btn-outline-twitter:focus, .btn-outline-twitter.focus {
    color: white;
    background-color: #1da1f2;
    border-color: #1da1f2;
  }

  .btn-outline-twitter:active, .btn-outline-twitter.active, .open > .btn-outline-twitter.dropdown-toggle {
    color: white;
    background-color: #1da1f2;
    border-color: #1da1f2;
  }

  .btn-outline-twitter:active:hover, .btn-outline-twitter:active:focus, .btn-outline-twitter:active.focus, .btn-outline-twitter.active:hover, .btn-outline-twitter.active:focus, .btn-outline-twitter.active.focus, .open > .btn-outline-twitter.dropdown-toggle:hover, .open > .btn-outline-twitter.dropdown-toggle:focus, .open > .btn-outline-twitter.dropdown-toggle.focus {
    color: white;
    background-color: #0a70ae;
    border-color: #085788;
  }

  .btn-outline-twitter.active {
    background-color: #0a70ae;
    border-color: #085788;
  }

  .btn-outline-twitter.disabled:focus, .btn-outline-twitter.disabled.focus, .btn-outline-twitter:disabled:focus, .btn-outline-twitter:disabled.focus {
    border-color: #7dc9f8;
  }

  .btn-outline-twitter.disabled:hover, .btn-outline-twitter:disabled:hover {
    border-color: #7dc9f8;
  }

  .btn-outline-facebook {
    color: #3b5998;
    background-image: none;
    background-color: transparent;
    border-color: #3b5998;
  }

  .btn-outline-facebook.btn-icon span {
    border-color: #3b5998;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-outline-facebook.btn-icon:hover span, .btn-outline-facebook.btn-icon:focus span, .btn-outline-facebook.btn-icon:active span, .btn-outline-facebook.btn-icon:active:focus span {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  .btn-outline-facebook:hover {
    color: white;
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .btn-outline-facebook:focus, .btn-outline-facebook.focus {
    color: white;
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .btn-outline-facebook:active, .btn-outline-facebook.active, .open > .btn-outline-facebook.dropdown-toggle {
    color: white;
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .btn-outline-facebook:active:hover, .btn-outline-facebook:active:focus, .btn-outline-facebook:active.focus, .btn-outline-facebook.active:hover, .btn-outline-facebook.active:focus, .btn-outline-facebook.active.focus, .open > .btn-outline-facebook.dropdown-toggle:hover, .open > .btn-outline-facebook.dropdown-toggle:focus, .open > .btn-outline-facebook.dropdown-toggle.focus {
    color: white;
    background-color: #23345a;
    border-color: #17233c;
  }

  .btn-outline-facebook.active {
    background-color: #23345a;
    border-color: #17233c;
  }

  .btn-outline-facebook.disabled:focus, .btn-outline-facebook.disabled.focus, .btn-outline-facebook:disabled:focus, .btn-outline-facebook:disabled.focus {
    border-color: #718dc8;
  }

  .btn-outline-facebook.disabled:hover, .btn-outline-facebook:disabled:hover {
    border-color: #718dc8;
  }

  .btn-outline-google-plus {
    color: #db4437;
    background-image: none;
    background-color: transparent;
    border-color: #db4437;
  }

  .btn-outline-google-plus.btn-icon span {
    border-color: #db4437;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-outline-google-plus.btn-icon:hover span, .btn-outline-google-plus.btn-icon:focus span, .btn-outline-google-plus.btn-icon:active span, .btn-outline-google-plus.btn-icon:active:focus span {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  .btn-outline-google-plus:hover {
    color: white;
    background-color: #db4437;
    border-color: #db4437;
  }

  .btn-outline-google-plus:focus, .btn-outline-google-plus.focus {
    color: white;
    background-color: #db4437;
    border-color: #db4437;
  }

  .btn-outline-google-plus:active, .btn-outline-google-plus.active, .open > .btn-outline-google-plus.dropdown-toggle {
    color: white;
    background-color: #db4437;
    border-color: #db4437;
  }

  .btn-outline-google-plus:active:hover, .btn-outline-google-plus:active:focus, .btn-outline-google-plus:active.focus, .btn-outline-google-plus.active:hover, .btn-outline-google-plus.active:focus, .btn-outline-google-plus.active.focus, .open > .btn-outline-google-plus.dropdown-toggle:hover, .open > .btn-outline-google-plus.dropdown-toggle:focus, .open > .btn-outline-google-plus.dropdown-toggle.focus {
    color: white;
    background-color: #9f271d;
    border-color: #7c1e16;
  }

  .btn-outline-google-plus.active {
    background-color: #9f271d;
    border-color: #7c1e16;
  }

  .btn-outline-google-plus.disabled:focus, .btn-outline-google-plus.disabled.focus, .btn-outline-google-plus:disabled:focus, .btn-outline-google-plus:disabled.focus {
    border-color: #eb958d;
  }

  .btn-outline-google-plus.disabled:hover, .btn-outline-google-plus:disabled:hover {
    border-color: #eb958d;
  }

  .btn-outline-github {
    color: #24292e;
    background-image: none;
    background-color: transparent;
    border-color: #24292e;
  }

  .btn-outline-github.btn-icon span {
    border-color: #24292e;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-outline-github.btn-icon:hover span, .btn-outline-github.btn-icon:focus span, .btn-outline-github.btn-icon:active span, .btn-outline-github.btn-icon:active:focus span {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  .btn-outline-github:hover {
    color: white;
    background-color: #24292e;
    border-color: #24292e;
  }

  .btn-outline-github:focus, .btn-outline-github.focus {
    color: white;
    background-color: #24292e;
    border-color: #24292e;
  }

  .btn-outline-github:active, .btn-outline-github.active, .open > .btn-outline-github.dropdown-toggle {
    color: white;
    background-color: #24292e;
    border-color: #24292e;
  }

  .btn-outline-github:active:hover, .btn-outline-github:active:focus, .btn-outline-github:active.focus, .btn-outline-github.active:hover, .btn-outline-github.active:focus, .btn-outline-github.active.focus, .open > .btn-outline-github.dropdown-toggle:hover, .open > .btn-outline-github.dropdown-toggle:focus, .open > .btn-outline-github.dropdown-toggle.focus {
    color: white;
    background-color: black;
    border-color: black;
  }

  .btn-outline-github.active {
    background-color: black;
    border-color: black;
  }

  .btn-outline-github.disabled:focus, .btn-outline-github.disabled.focus, .btn-outline-github:disabled:focus, .btn-outline-github:disabled.focus {
    border-color: #515c67;
  }

  .btn-outline-github.disabled:hover, .btn-outline-github:disabled:hover {
    border-color: #515c67;
  }

  /*///////////////////////////////////
  // Tabs
  ///////////////////////////////////*/
  @-webkit-keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-webkit-keyframes fadeOutRight {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
    }
  }

  @keyframes fadeOutRight {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
    }
  }

  .tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .tabs .nav-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    position: relative;
    border-color: transparent;
  }

  .tabs .nav-bar .indicator {
    content: '';
    position: absolute;
    display: block;
    bottom: -2px;
    height: 2px;
    background-color: #007AFF;
    -webkit-transition: left 0.4s ease-in-out, right 0.2s ease-in-out;
    -o-transition: left 0.4s ease-in-out, right 0.2s ease-in-out;
    transition: left 0.4s ease-in-out, right 0.2s ease-in-out;
  }

  .tabs .nav-bar .indicator.transition-reverse {
    -webkit-transition: left 0.2s ease-in-out, right 0.4s ease-in-out;
    -o-transition: left 0.2s ease-in-out, right 0.4s ease-in-out;
    transition: left 0.2s ease-in-out, right 0.4s ease-in-out;
  }

  .tabs .nav-bar.nav-bar-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .tabs .nav-bar.nav-bar-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .tabs .nav-bar .nav-bar-item {
    padding: 0.4rem 2.5rem;
    font-size: 0.8rem;
    line-height: 1rem;
    font-family: 'Quicksand', sans-serif;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
  }

  @media (max-width: 35.99em) {
    .tabs .nav-bar .nav-bar-item {
      padding: 0.4rem 1.25rem;
    }
  }

  .tabs .nav-bar .nav-bar-item.active {
    color: #007AFF;
  }

  .tabs .tab-contents {
    margin-top: 30px;
    position: relative;
  }

  .tabs .tab-contents .tab-content {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .tabs .tab-contents .tab-content:not(.active) {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 1;
    -webkit-animation: fadeOutRight .25s ease forwards;
    animation: fadeOutRight .25s ease forwards;
  }

  .tabs .tab-contents .tab-content.active {
    -webkit-animation: fadeInLeft .5s ease forwards;
    animation: fadeInLeft .5s ease forwards;
  }

  /*///////////////////////////////////
  // Forms
  ///////////////////////////////////*/
  .form-control-feedback {
    right: 8px;
    top: auto !important;
    bottom: 0.8rem;
    height: auto !important;
    line-height: 1.5 !important;
  }

  .has-success .form-control {
    border-color: #4CD964;
  }

  .has-success .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .has-success .form-control-feedback {
    color: #4CD964;
  }

  .has-warning .form-control {
    border-color: #FF9500;
  }

  .has-warning .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .has-warning .form-control-feedback {
    color: #FF9500;
  }

  .has-error .form-control {
    border-color: #FF3B30;
  }

  .has-error .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .has-error .form-control-feedback {
    color: #FF3B30;
  }

  .form-control, .StripeElement{
    padding: 0.8rem;
    line-height: 1.5;
    border-color: #e1e1e1;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 3px;
    background-color: #fafafa;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    height: auto;
    font-family: 'Quicksand', sans-serif!important;
    font-size: 16px!important;
    color: black!important;
  }
  .StripeElement {
    margin-bottom: 20px;
    font-family: 'Quicksand', sans-serif!important;
  }
  .InputContainer .InputElement {
    font-family: 'Quicksand', sans-serif!important;
  }
  .form-control:focus {
    background-color: #fff !important;
    border-color: #007AFF;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #forms-2 {
    padding: 10px 30px;
  }

  .form-control.input-xs {
    padding: 0.2rem;
    height: auto;
  }

  .form-control.input-xs + .form-control-feedback {
    bottom: 0.2rem;
  }

  .form-control.input-sm {
    padding: 0.4rem;
    height: auto;
  }

  .form-control.input-sm + .form-control-feedback {
    bottom: 0.4rem;
  }

  .form-control.input-lg {
    padding: 1.4rem;
    height: auto;
    font-size: 1rem;
  }

  .form-control.input-lg + .form-control-feedback {
    bottom: 1.4rem;
    font-size: 1rem;
  }

  .form-control.active, .form-control:active {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  label, legend {
    font-size: 0.8rem;
    font-weight: 600;
  }

  label, input, textarea, legend {
    font-family: 'Quicksand', sans-serif;
  }

  input[type='file'] {
    display: none;
  }

  .inputfile + label svg {
    fill: currentColor;
    vertical-align: middle;
    margin-right: 8px;
  }

  .ios-toggle, .ios-toggle:active {
    height: 0;
    width: 0;
    opacity: 0;
  }

  .ios-toggle:checked + .checkbox-label {
    -webkit-box-shadow: inset 0 0 0 20px #4CD964, 0 0 0 1px #4CD964;
    box-shadow: inset 0 0 0 20px #4CD964, 0 0 0 1px #4CD964;
  }

  .ios-toggle:checked + .checkbox-label:before {
    left: calc(100% - 32px);
    -webkit-box-shadow: 0 0 0 1px transparent, -3px 0px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px transparent, -3px 0px 3px rgba(0, 0, 0, 0.1);
  }

  .ios-toggle:checked + .checkbox-label:after {
    content: attr(data-on);
    left: 70px;
    width: 32px;
  }

  .checkbox-label {
    display: block;
    position: relative;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 16px;
    width: 70px;
    height: 32px;
    border-radius: 50px;
    background-color: #fafafa;
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 0 0px #4CD964, 0 0 0 1px #e1e1e1;
    box-shadow: inset 0 0 0 0px #4CD964, 0 0 0 1px #e1e1e1;
    -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
    transition: -webkit-box-shadow 0.2s ease-in-out;
    -o-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  }

  .checkbox-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 32px;
    width: 32px;
    top: 0px;
    left: 0px;
    right: auto;
    background-color: white;
    border-radius: 50px;
    -webkit-box-shadow: 3px 0 3px rgba(0, 0, 0, 0.1), 0 0 0 1px #e1e1e1;
    box-shadow: 3px 0 3px rgba(0, 0, 0, 0.1), 0 0 0 1px #e1e1e1;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .checkbox-label:after {
    content: attr(data-off);
    display: block;
    position: absolute;
    top: 0;
    left: -300px;
    padding: 10px;
    height: 100%;
    width: 300px;
    text-align: right;
    color: #2b2b2b;
    white-space: nowrap;
  }

  .checkbox-label.radius-square {
    border-radius: 3px;
  }

  .checkbox-label.radius-square:before {
    border-radius: 3px;
  }

  .zinput {
    position: relative;
  }

  .zinput label {
    display: inline-block;
    position: relative;
    padding-left: 2.5rem;
    cursor: pointer;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
  }

  .zinput label:before {
    width: 32px;
    height: 32px;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    cursor: pointer;
    content: '';
    border: 1px solid #e1e1e1;
    -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
    transition: -webkit-box-shadow 0.2s ease-in-out;
    -o-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    -webkit-box-shadow: inset 0 0 0 0px #4CD964, 0 0 0 0 #e1e1e1;
    box-shadow: inset 0 0 0 0px #4CD964, 0 0 0 0 #e1e1e1;
  }

  .zinput.zradio {
    padding: 0.8rem 0;
  }

  .zinput.zradio input[type='radio'] {
    opacity: 0;
    width: 0;
    height: 0;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    cursor: pointer;
  }

  .zinput.zradio input[type='radio']:checked + label:before {
    border-color: transparent;
  }

  .zinput.zradio input[type='radio'] + label:before {
    border-radius: 50px;
  }

  .zinput.zradio input[type='radio']:checked + label:before {
    -webkit-box-shadow: inset 0 0 0 9px #4CD964, 0 0 0 0 #4CD964;
    box-shadow: inset 0 0 0 9px #4CD964, 0 0 0 0 #4CD964;
  }

  .zinput.zcheckbox {
    padding: 0.8rem 0;
  }

  .zinput.zcheckbox input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    cursor: pointer;
  }

  .zinput.zcheckbox input[type='checkbox']:checked + label:before {
    border-color: transparent;
  }

  .zinput.zcheckbox input[type='checkbox'] + label:before {
    border-radius: 3px;
  }

  .zinput.zcheckbox input[type='checkbox']:checked + label:before {
    -webkit-box-shadow: inset 0 0 0 20px #4CD964, 0 0 0 0 #4CD964;
    box-shadow: inset 0 0 0 20px #4CD964, 0 0 0 0 #4CD964;
  }

  .zinput svg {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    -webkit-transform: translateY(-65%);
    -ms-transform: translateY(-65%);
    transform: translateY(-65%);
    left: 8px;
    pointer-events: none;
  }

  .zinput svg path {
    stroke: #fff;
    stroke-width: 18px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
  }

  /*///////////////////////////////////
  // Tables
  ///////////////////////////////////*/
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    padding: 1rem;
    border-top: 0;
  }

  .table > thead > tr > th {
    border-bottom: 1px solid #e1e1e1;
  }

  .table {
    border: 1px solid #e1e1e1;
    background-color: #fff;
    border-radius: 3px;
  }

  .table tbody tr:not(:first-child) {
    border-top: 1px solid #e1e1e1;
  }

  .table th, .table td {
    vertical-align: middle;
    border: 0;
  }

  .table thead {
    background-color: #f2f2f2;
  }

  .table thead th {
    vertical-align: bottom;
    font-family: 'Quicksand', sans-serif;
    font-size: 0.8rem;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background-color: #fafafa;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }

  /*///////////////////////////////////
  // Border
  ///////////////////////////////////*/
  .border {
    border: 1px solid currentColor !important;
  }

  .border-top {
    border-top: 1px solid currentColor !important;
  }

  .border-right {
    border-right: 1px solid currentColor !important;
  }

  .border-bottom {
    border-bottom: 1px solid currentColor !important;
  }

  .border-left {
    border-left: 1px solid currentColor !important;
  }

  .border-x {
    border-left: 1px solid currentColor !important;
    border-right: 1px solid currentColor !important;
  }

  .border-y {
    border-top: 1px solid currentColor !important;
    border-bottom: 1px solid currentColor !important;
  }

  .border-dashed {
    border: 1px dashed currentColor !important;
  }

  .border-dashed-top {
    border-top: 1px dashed currentColor !important;
  }

  .border-dashed-right {
    border-right: 1px dashed currentColor !important;
  }

  .border-dashed-bottom {
    border-bottom: 1px dashed currentColor !important;
  }

  .border-dashed-left {
    border-left: 1px dashed currentColor !important;
  }

  .border-dashed-x {
    border-left: 1px dashed currentColor !important;
    border-right: 1px dashed currentColor !important;
  }

  .border-dashed-y {
    border-top: 1px dashed currentColor !important;
    border-bottom: 1px dashed currentColor !important;
  }

  .border-0 {
    border-width: 0 !important;
  }

  .border-top-0 {
    border-top-width: 0 !important;
  }

  .border-right-0 {
    border-right-width: 0 !important;
  }

  .border-bottom-0 {
    border-bottom-width: 0 !important;
  }

  .border-left-0 {
    border-left-width: 0 !important;
  }

  .border-x-0 {
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

  .border-y-0 {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }

  .border-2x {
    border-width: 2px !important;
  }

  @media (min-width: 36em) {
    .border-sm {
      border: 1px solid currentColor !important;
    }
    .border-sm-top {
      border-top: 1px solid currentColor !important;
    }
    .border-sm-right {
      border-right: 1px solid currentColor !important;
    }
    .border-sm-bottom {
      border-bottom: 1px solid currentColor !important;
    }
    .border-sm-left {
      border-left: 1px solid currentColor !important;
    }
    .border-sm-x {
      border-left: 1px solid currentColor !important;
      border-right: 1px solid currentColor !important;
    }
    .border-sm-y {
      border-top: 1px solid currentColor !important;
      border-bottom: 1px solid currentColor !important;
    }
    .border-sm-dashed {
      border: 1px dashed currentColor !important;
    }
    .border-sm-dashed-top {
      border-top: 1px dashed currentColor !important;
    }
    .border-sm-dashed-right {
      border-right: 1px dashed currentColor !important;
    }
    .border-sm-dashed-bottom {
      border-bottom: 1px dashed currentColor !important;
    }
    .border-sm-dashed-left {
      border-left: 1px dashed currentColor !important;
    }
    .border-sm-dashed-x {
      border-left: 1px dashed currentColor !important;
      border-right: 1px dashed currentColor !important;
    }
    .border-sm-dashed-y {
      border-top: 1px dashed currentColor !important;
      border-bottom: 1px dashed currentColor !important;
    }
    .border-sm-0 {
      border-width: 0 !important;
    }
    .border-sm-top-0 {
      border-top-width: 0 !important;
    }
    .border-sm-right-0 {
      border-right-width: 0 !important;
    }
    .border-sm-bottom-0 {
      border-bottom-width: 0 !important;
    }
    .border-sm-left-0 {
      border-left-width: 0 !important;
    }
    .border-sm-x-0 {
      border-left-width: 0 !important;
      border-right-width: 0 !important;
    }
    .border-sm-y-0 {
      border-top-width: 0 !important;
      border-bottom-width: 0 !important;
    }
  }

  @media (min-width: 48em) {
    .border-md {
      border: 1px solid currentColor !important;
    }
    .border-md-top {
      border-top: 1px solid currentColor !important;
    }
    .border-md-right {
      border-right: 1px solid currentColor !important;
    }
    .border-md-bottom {
      border-bottom: 1px solid currentColor !important;
    }
    .border-md-left {
      border-left: 1px solid currentColor !important;
    }
    .border-md-x {
      border-left: 1px solid currentColor !important;
      border-right: 1px solid currentColor !important;
    }
    .border-md-y {
      border-top: 1px solid currentColor !important;
      border-bottom: 1px solid currentColor !important;
    }
    .border-md-dashed {
      border: 1px dashed currentColor !important;
    }
    .border-md-dashed-top {
      border-top: 1px dashed currentColor !important;
    }
    .border-md-dashed-right {
      border-right: 1px dashed currentColor !important;
    }
    .border-md-dashed-bottom {
      border-bottom: 1px dashed currentColor !important;
    }
    .border-md-dashed-left {
      border-left: 1px dashed currentColor !important;
    }
    .border-md-dashed-x {
      border-left: 1px dashed currentColor !important;
      border-right: 1px dashed currentColor !important;
    }
    .border-md-dashed-y {
      border-top: 1px dashed currentColor !important;
      border-bottom: 1px dashed currentColor !important;
    }
    .border-md-0 {
      border-width: 0 !important;
    }
    .border-md-top-0 {
      border-top-width: 0 !important;
    }
    .border-md-right-0 {
      border-right-width: 0 !important;
    }
    .border-md-bottom-0 {
      border-bottom-width: 0 !important;
    }
    .border-md-left-0 {
      border-left-width: 0 !important;
    }
    .border-md-x-0 {
      border-left-width: 0 !important;
      border-right-width: 0 !important;
    }
    .border-md-y-0 {
      border-top-width: 0 !important;
      border-bottom-width: 0 !important;
    }
  }

  @media (min-width: 62em) {
    .border-lg {
      border: 1px solid currentColor !important;
    }
    .border-lg-top {
      border-top: 1px solid currentColor !important;
    }
    .border-lg-right {
      border-right: 1px solid currentColor !important;
    }
    .border-lg-bottom {
      border-bottom: 1px solid currentColor !important;
    }
    .border-lg-left {
      border-left: 1px solid currentColor !important;
    }
    .border-lg-x {
      border-left: 1px solid currentColor !important;
      border-right: 1px solid currentColor !important;
    }
    .border-lg-y {
      border-top: 1px solid currentColor !important;
      border-bottom: 1px solid currentColor !important;
    }
    .border-lg-dashed {
      border: 1px dashed currentColor !important;
    }
    .border-lg-dashed-top {
      border-top: 1px dashed currentColor !important;
    }
    .border-lg-dashed-right {
      border-right: 1px dashed currentColor !important;
    }
    .border-lg-dashed-bottom {
      border-bottom: 1px dashed currentColor !important;
    }
    .border-lg-dashed-left {
      border-left: 1px dashed currentColor !important;
    }
    .border-lg-dashed-x {
      border-left: 1px dashed currentColor !important;
      border-right: 1px dashed currentColor !important;
    }
    .border-lg-dashed-y {
      border-top: 1px dashed currentColor !important;
      border-bottom: 1px dashed currentColor !important;
    }
    .border-lg-0 {
      border-width: 0 !important;
    }
    .border-lg-top-0 {
      border-top-width: 0 !important;
    }
    .border-lg-right-0 {
      border-right-width: 0 !important;
    }
    .border-lg-bottom-0 {
      border-bottom-width: 0 !important;
    }
    .border-lg-left-0 {
      border-left-width: 0 !important;
    }
    .border-lg-x-0 {
      border-left-width: 0 !important;
      border-right-width: 0 !important;
    }
    .border-lg-y-0 {
      border-top-width: 0 !important;
      border-bottom-width: 0 !important;
    }
  }

  @media (min-width: 75em) {
    .border-xl {
      border: 1px solid currentColor !important;
    }
    .border-xl-top {
      border-top: 1px solid currentColor !important;
    }
    .border-xl-right {
      border-right: 1px solid currentColor !important;
    }
    .border-xl-bottom {
      border-bottom: 1px solid currentColor !important;
    }
    .border-xl-left {
      border-left: 1px solid currentColor !important;
    }
    .border-xl-x {
      border-left: 1px solid currentColor !important;
      border-right: 1px solid currentColor !important;
    }
    .border-xl-y {
      border-top: 1px solid currentColor !important;
      border-bottom: 1px solid currentColor !important;
    }
    .border-xl-dashed {
      border: 1px dashed currentColor !important;
    }
    .border-xl-dashed-top {
      border-top: 1px dashed currentColor !important;
    }
    .border-xl-dashed-right {
      border-right: 1px dashed currentColor !important;
    }
    .border-xl-dashed-bottom {
      border-bottom: 1px dashed currentColor !important;
    }
    .border-xl-dashed-left {
      border-left: 1px dashed currentColor !important;
    }
    .border-xl-dashed-x {
      border-left: 1px dashed currentColor !important;
      border-right: 1px dashed currentColor !important;
    }
    .border-xl-dashed-y {
      border-top: 1px dashed currentColor !important;
      border-bottom: 1px dashed currentColor !important;
    }
    .border-xl-0 {
      border-width: 0 !important;
    }
    .border-xl-top-0 {
      border-top-width: 0 !important;
    }
    .border-xl-right-0 {
      border-right-width: 0 !important;
    }
    .border-xl-bottom-0 {
      border-bottom-width: 0 !important;
    }
    .border-xl-left-0 {
      border-left-width: 0 !important;
    }
    .border-xl-x-0 {
      border-left-width: 0 !important;
      border-right-width: 0 !important;
    }
    .border-xl-y-0 {
      border-top-width: 0 !important;
      border-bottom-width: 0 !important;
    }
  }

  /********************************
      Responsive border radius
  ********************************/
  .radius-0 {
    border-radius: 0 !important;
  }

  .radius-tr-0 {
    border-top-right-radius: 0 !important;
  }

  .radius-tl-0 {
    border-top-left-radius: 0 !important;
  }

  .radius-br-0 {
    border-bottom-right-radius: 0 !important;
  }

  .radius-bl-0 {
    border-bottom-left-radius: 0 !important;
  }

  .radius-primary {
    border-radius: 3px !important;
  }

  .radius-tr-primary {
    border-top-right-radius: 3px !important;
  }

  .radius-tl-primary {
    border-top-left-radius: 3px !important;
  }

  .radius-br-primary {
    border-bottom-right-radius: 3px !important;
  }

  .radius-bl-primary {
    border-bottom-left-radius: 3px !important;
  }

  .radius-secondary {
    border-radius: 10px !important;
  }

  .radius-tr-secondary {
    border-top-right-radius: 10px !important;
  }

  .radius-tl-secondary {
    border-top-left-radius: 10px !important;
  }

  .radius-br-secondary {
    border-bottom-right-radius: 10px !important;
  }

  .radius-bl-secondary {
    border-bottom-left-radius: 10px !important;
  }

  .radius-capsule {
    border-radius: 50px !important;
  }

  .radius-tr-capsule {
    border-top-right-radius: 50px !important;
  }

  .radius-tl-capsule {
    border-top-left-radius: 50px !important;
  }

  .radius-br-capsule {
    border-bottom-right-radius: 50px !important;
  }

  .radius-bl-capsule {
    border-bottom-left-radius: 50px !important;
  }

  .radius-round {
    border-radius: 100% !important;
  }

  .radius-tr-round {
    border-top-right-radius: 100% !important;
  }

  .radius-tl-round {
    border-top-left-radius: 100% !important;
  }

  .radius-br-round {
    border-bottom-right-radius: 100% !important;
  }

  .radius-bl-round {
    border-bottom-left-radius: 100% !important;
  }

  @media (min-width: 36em) {
    .radius-sm-0 {
      border-radius: 0 !important;
    }
    .radius-sm-tr-0 {
      border-top-right-radius: 0 !important;
    }
    .radius-sm-tl-0 {
      border-top-left-radius: 0 !important;
    }
    .radius-sm-br-0 {
      border-bottom-right-radius: 0 !important;
    }
    .radius-sm-bl-0 {
      border-bottom-left-radius: 0 !important;
    }
    .radius-sm-primary {
      border-radius: 3px !important;
    }
    .radius-sm-tr-primary {
      border-top-right-radius: 3px !important;
    }
    .radius-sm-tl-primary {
      border-top-left-radius: 3px !important;
    }
    .radius-sm-br-primary {
      border-bottom-right-radius: 3px !important;
    }
    .radius-sm-bl-primary {
      border-bottom-left-radius: 3px !important;
    }
    .radius-sm-secondary {
      border-radius: 10px !important;
    }
    .radius-sm-tr-secondary {
      border-top-right-radius: 10px !important;
    }
    .radius-sm-tl-secondary {
      border-top-left-radius: 10px !important;
    }
    .radius-sm-br-secondary {
      border-bottom-right-radius: 10px !important;
    }
    .radius-sm-bl-secondary {
      border-bottom-left-radius: 10px !important;
    }
    .radius-sm-capsule {
      border-radius: 50px !important;
    }
    .radius-sm-tr-capsule {
      border-top-right-radius: 50px !important;
    }
    .radius-sm-tl-capsule {
      border-top-left-radius: 50px !important;
    }
    .radius-sm-br-capsule {
      border-bottom-right-radius: 50px !important;
    }
    .radius-sm-bl-capsule {
      border-bottom-left-radius: 50px !important;
    }
    .radius-sm-round {
      border-radius: 100% !important;
    }
    .radius-sm-tr-round {
      border-top-right-radius: 100% !important;
    }
    .radius-sm-tl-round {
      border-top-left-radius: 100% !important;
    }
    .radius-sm-br-round {
      border-bottom-right-radius: 100% !important;
    }
    .radius-sm-bl-round {
      border-bottom-left-radius: 100% !important;
    }
  }

  @media (min-width: 48em) {
    .radius-md-0 {
      border-radius: 0 !important;
    }
    .radius-md-tr-0 {
      border-top-right-radius: 0 !important;
    }
    .radius-md-tl-0 {
      border-top-left-radius: 0 !important;
    }
    .radius-md-br-0 {
      border-bottom-right-radius: 0 !important;
    }
    .radius-md-bl-0 {
      border-bottom-left-radius: 0 !important;
    }
    .radius-md-primary {
      border-radius: 3px !important;
    }
    .radius-md-tr-primary {
      border-top-right-radius: 3px !important;
    }
    .radius-md-tl-primary {
      border-top-left-radius: 3px !important;
    }
    .radius-md-br-primary {
      border-bottom-right-radius: 3px !important;
    }
    .radius-md-bl-primary {
      border-bottom-left-radius: 3px !important;
    }
    .radius-md-secondary {
      border-radius: 10px !important;
    }
    .radius-md-tr-secondary {
      border-top-right-radius: 10px !important;
    }
    .radius-md-tl-secondary {
      border-top-left-radius: 10px !important;
    }
    .radius-md-br-secondary {
      border-bottom-right-radius: 10px !important;
    }
    .radius-md-bl-secondary {
      border-bottom-left-radius: 10px !important;
    }
    .radius-md-capsule {
      border-radius: 50px !important;
    }
    .radius-md-tr-capsule {
      border-top-right-radius: 50px !important;
    }
    .radius-md-tl-capsule {
      border-top-left-radius: 50px !important;
    }
    .radius-md-br-capsule {
      border-bottom-right-radius: 50px !important;
    }
    .radius-md-bl-capsule {
      border-bottom-left-radius: 50px !important;
    }
    .radius-md-round {
      border-radius: 100% !important;
    }
    .radius-md-tr-round {
      border-top-right-radius: 100% !important;
    }
    .radius-md-tl-round {
      border-top-left-radius: 100% !important;
    }
    .radius-md-br-round {
      border-bottom-right-radius: 100% !important;
    }
    .radius-md-bl-round {
      border-bottom-left-radius: 100% !important;
    }
  }

  @media (min-width: 62em) {
    .radius-lg-0 {
      border-radius: 0 !important;
    }
    .radius-lg-tr-0 {
      border-top-right-radius: 0 !important;
    }
    .radius-lg-tl-0 {
      border-top-left-radius: 0 !important;
    }
    .radius-lg-br-0 {
      border-bottom-right-radius: 0 !important;
    }
    .radius-lg-bl-0 {
      border-bottom-left-radius: 0 !important;
    }
    .radius-lg-primary {
      border-radius: 3px !important;
    }
    .radius-lg-tr-primary {
      border-top-right-radius: 3px !important;
    }
    .radius-lg-tl-primary {
      border-top-left-radius: 3px !important;
    }
    .radius-lg-br-primary {
      border-bottom-right-radius: 3px !important;
    }
    .radius-lg-bl-primary {
      border-bottom-left-radius: 3px !important;
    }
    .radius-lg-secondary {
      border-radius: 10px !important;
    }
    .radius-lg-tr-secondary {
      border-top-right-radius: 10px !important;
    }
    .radius-lg-tl-secondary {
      border-top-left-radius: 10px !important;
    }
    .radius-lg-br-secondary {
      border-bottom-right-radius: 10px !important;
    }
    .radius-lg-bl-secondary {
      border-bottom-left-radius: 10px !important;
    }
    .radius-lg-capsule {
      border-radius: 50px !important;
    }
    .radius-lg-tr-capsule {
      border-top-right-radius: 50px !important;
    }
    .radius-lg-tl-capsule {
      border-top-left-radius: 50px !important;
    }
    .radius-lg-br-capsule {
      border-bottom-right-radius: 50px !important;
    }
    .radius-lg-bl-capsule {
      border-bottom-left-radius: 50px !important;
    }
    .radius-lg-round {
      border-radius: 100% !important;
    }
    .radius-lg-tr-round {
      border-top-right-radius: 100% !important;
    }
    .radius-lg-tl-round {
      border-top-left-radius: 100% !important;
    }
    .radius-lg-br-round {
      border-bottom-right-radius: 100% !important;
    }
    .radius-lg-bl-round {
      border-bottom-left-radius: 100% !important;
    }
  }

  @media (min-width: 75em) {
    .radius-xl-0 {
      border-radius: 0 !important;
    }
    .radius-xl-tr-0 {
      border-top-right-radius: 0 !important;
    }
    .radius-xl-tl-0 {
      border-top-left-radius: 0 !important;
    }
    .radius-xl-br-0 {
      border-bottom-right-radius: 0 !important;
    }
    .radius-xl-bl-0 {
      border-bottom-left-radius: 0 !important;
    }
    .radius-xl-primary {
      border-radius: 3px !important;
    }
    .radius-xl-tr-primary {
      border-top-right-radius: 3px !important;
    }
    .radius-xl-tl-primary {
      border-top-left-radius: 3px !important;
    }
    .radius-xl-br-primary {
      border-bottom-right-radius: 3px !important;
    }
    .radius-xl-bl-primary {
      border-bottom-left-radius: 3px !important;
    }
    .radius-xl-secondary {
      border-radius: 10px !important;
    }
    .radius-xl-tr-secondary {
      border-top-right-radius: 10px !important;
    }
    .radius-xl-tl-secondary {
      border-top-left-radius: 10px !important;
    }
    .radius-xl-br-secondary {
      border-bottom-right-radius: 10px !important;
    }
    .radius-xl-bl-secondary {
      border-bottom-left-radius: 10px !important;
    }
    .radius-xl-capsule {
      border-radius: 50px !important;
    }
    .radius-xl-tr-capsule {
      border-top-right-radius: 50px !important;
    }
    .radius-xl-tl-capsule {
      border-top-left-radius: 50px !important;
    }
    .radius-xl-br-capsule {
      border-bottom-right-radius: 50px !important;
    }
    .radius-xl-bl-capsule {
      border-bottom-left-radius: 50px !important;
    }
    .radius-xl-round {
      border-radius: 100% !important;
    }
    .radius-xl-tr-round {
      border-top-right-radius: 100% !important;
    }
    .radius-xl-tl-round {
      border-top-left-radius: 100% !important;
    }
    .radius-xl-br-round {
      border-bottom-right-radius: 100% !important;
    }
    .radius-xl-bl-round {
      border-bottom-left-radius: 100% !important;
    }
  }

  /*///////////////////////////////////
  // Helpers
  ///////////////////////////////////*/
  .container {
    position: relative;
  }

  section {
    position: relative;
    padding: 7.5rem 0;
  }

  @media (max-width: 47.99em) {
    section {
      padding: 4rem 0;
    }
  }

  .h-full {
    min-height: 100vh;
  }

  @media (min-width: 62em) {
    .h-lg-full {
      min-height: 100vh;
    }
  }

  .h-half {
    min-height: 50vh;
  }

  .h-100vh {
    height: 100vh !important;
  }

  @media (min-width: 36em) {
    .h-sm-100vh {
      height: 100vh !important;
    }
  }

  @media (min-width: 48em) {
    .h-md-100vh {
      height: 100vh !important;
    }
  }

  @media (min-width: 62em) {
    .h-lg-100vh {
      height: 100vh !important;
    }
  }

  @media (min-width: 75em) {
    .h-xl-100vh {
      height: 100vh !important;
    }
  }

  .perpendicular {
    position: absolute;
    bottom: 0;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    line-height: 0;
  }

  /* Background */
  .background-holder {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    z-index: -1;
    overflow: hidden;
    will-change: transform, opacity, filter;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-repeat: no-repeat;
  }

  .background-holder.background-fixed {
    background-attachment: fixed;
  }

  .background-holder.background-right {
    left: auto;
    right: 0;
  }

  .background-holder.overlay:before {
    position: absolute;
    content: '';
    background: rgba(0, 0, 0, 0.25);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .background-holder.overlay-primary:before {
    background: rgba(0, 122, 255, 0.4);
  }

  .background-holder.overlay-info:before {
    background: rgba(90, 200, 250, 0.4);
  }

  .background-holder.overlay-success:before {
    background: rgba(76, 217, 100, 0.4);
  }

  .background-holder.overlay-warning:before {
    background: rgba(255, 149, 0, 0.4);
  }

  .background-holder.overlay-danger:before {
    background: rgba(255, 59, 48, 0.4);
  }

  .background-holder.overlay-0:before {
    background: rgba(0, 0, 0, 0.7);
  }

  .background-holder.overlay-1:before {
    background: rgba(0, 0, 0, 0.55);
  }

  .background-holder.overlay-2:before {
    background: rgba(0, 0, 0, 0.4);
  }

  .background-holder video {
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    min-width: 100%;
  }

  .background-holder .youtube-background {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .background-holder .youtube-background .mb_YTPBar {
    display: none !important;
  }

  .has-parallax {
    position: relative;
    overflow: hidden;
  }

  .overflow-visible {
    overflow: visible !important;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-hidden-x {
    overflow-x: hidden;
  }

  .overflow-hidden-y {
    overflow-y: hidden;
  }

  hr {
    border-color: currentColor;
  }

  hr.left {
    margin-left: 0;
  }

  hr.short {
    width: 3.05176rem;
  }

  .color-black {
    color: black !important;
  }

  .background-black {
    background-color: black !important;
  }

  .border-color-black {
    border-color: black !important;
  }

  .color-1 {
    color: #141414 !important;
  }

  .background-1 {
    background-color: #141414 !important;
  }

  .border-color-1 {
    border-color: #141414 !important;
  }

  .color-2 {
    color: #2b2b2b !important;
  }

  .background-2 {
    background-color: #2b2b2b !important;
  }

  .border-color-2 {
    border-color: #2b2b2b !important;
  }

  .color-3 {
    color: #404040 !important;
  }

  .background-3 {
    background-color: #404040 !important;
  }

  .border-color-3 {
    border-color: #404040 !important;
  }

  .color-4 {
    color: #555555 !important;
  }

  .background-4 {
    background-color: #555555 !important;
  }

  .border-color-4 {
    border-color: #555555 !important;
  }

  .color-5 {
    color: #6a6a6a !important;
  }

  .background-5 {
    background-color: #6a6a6a !important;
  }

  .border-color-5 {
    border-color: #6a6a6a !important;
  }

  .color-6 {
    color: #7F7F7F !important;
  }

  .background-6 {
    background-color: #7F7F7F !important;
  }

  .border-color-6 {
    border-color: #7F7F7F !important;
  }

  .color-7 {
    color: #000 !important;
  }

  .background-7 {
    background-color: #949494 !important;
  }

  .border-color-7 {
    border-color: #949494 !important;
  }

  .color-8 {
    color: #bebebe !important;
  }

  .background-8 {
    background-color: #bebebe !important;
  }

  .border-color-8 {
    border-color: #bebebe !important;
  }

  .color-9 {
    color: #e1e1e1 !important;
  }

  .background-9 {
    background-color: #e1e1e1 !important;
  }

  .border-color-9 {
    border-color: #e1e1e1 !important;
  }

  .color-10 {
    color: #f2f2f2 !important;
  }

  .background-10 {
    background-color: #f2f2f2 !important;
  }

  .border-color-10 {
    border-color: #f2f2f2 !important;
  }

  .color-11 {
    color: #fafafa !important;
  }

  .background-11 {
    background-color: #fafafa !important;
  }

  .border-color-11 {
    border-color: #fafafa !important;
  }

  .color-white {
    color: white !important;
  }

  .background-white {
    background-color: white !important;
  }

  .border-color-white {
    border-color: white !important;
  }

  .color-primary {
    color: #007AFF !important;
  }

  .background-primary {
    background-color: #007AFF !important;
  }

  .border-color-primary {
    border-color: #007AFF !important;
  }

  .color-info {
    color: #5AC8FA !important;
  }

  .background-info {
    background-color: #5AC8FA !important;
  }

  .border-color-info {
    border-color: #5AC8FA !important;
  }

  .color-success {
    color: #4CD964 !important;
  }

  .background-success {
    background-color: #4CD964 !important;
  }

  .border-color-success {
    border-color: #4CD964 !important;
  }

  .color-warning {
    color: #FF9500 !important;
  }

  .background-warning {
    background-color: #FF9500 !important;
  }

  .border-color-warning {
    border-color: #FF9500 !important;
  }

  .color-danger {
    color: #FF3B30 !important;
  }

  .background-danger {
    background-color: #FF3B30 !important;
  }

  .border-color-danger {
    border-color: #FF3B30 !important;
  }

  .color-facebook {
    color: #3b5998 !important;
  }

  .background-facebook {
    background-color: #3b5998 !important;
  }

  .border-color-facebook {
    border-color: #3b5998 !important;
  }

  .color-twitter {
    color: #1da1f2 !important;
  }

  .background-twitter {
    background-color: #1da1f2 !important;
  }

  .border-color-twitter {
    border-color: #1da1f2 !important;
  }

  .color-google-plus {
    color: #db4437 !important;
  }

  .background-google-plus {
    background-color: #db4437 !important;
  }

  .border-color-google-plus {
    border-color: #db4437 !important;
  }

  .color-github {
    color: #24292e !important;
  }

  .background-github {
    background-color: #24292e !important;
  }

  .border-color-github {
    border-color: #24292e !important;
  }

  .color-pinterest {
    color: #bd081d !important;
  }

  .background-pinterest {
    background-color: #bd081d !important;
  }

  .border-color-pinterest {
    border-color: #bd081d !important;
  }

  .color-instagram {
    color: #c53f70 !important;
  }

  .background-instagram {
    background-color: #c53f70 !important;
  }

  .border-color-instagram {
    border-color: #c53f70 !important;
  }

  .background-transparent {
    background-color: transparent !important;
  }

  .background-transparent:active, .background-transparent:hover, .background-transparent:focus, .background-transparent:active:focus {
    background-color: transparent !important;
  }

  .badge-white {
    background-color: #ffffff;
  }

  .badge-primary {
    background-color: #007AFF;
  }

  .badge-info {
    background-color: #5AC8FA;
  }

  .badge-success {
    background-color: #4CD964;
  }

  .badge-warning {
    background-color: #FF9500;
  }

  .badge-danger {
    background-color: #FF3B30;
  }

  .fit-fill {
    -o-object-fit: fill;
    object-fit: fill;
  }

  .fit-contain {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .fit-cover {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .fit-none {
    -o-object-fit: none;
    object-fit: none;
  }

  .fit-scale-down {
    -o-object-fit: scale-down;
    object-fit: scale-down;
  }

  .opacity-75 {
    opacity: 0.75;
  }

  .opacity-50 {
    opacity: 0.50;
  }

  .elevated {
    -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  }

  .box-shadow-wide {
    -webkit-box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
    box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  }

  .r-0 {
    right: 0;
  }

  .l-0 {
    left: 0;
  }

  .t-0 {
    top: 0;
  }

  .b-0 {
    bottom: 0;
  }

  .a-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .absolute-centered {
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  @media (min-width: 62em) {
    .absolute-centered-lg {
      left: 50%;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .sticky-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .pos-relative {
    position: relative;
  }

  .pos-absolute {
    position: absolute;
  }

  .pos-fixed {
    position: fixed;
  }

  .w-100 {
    width: 100% !important;
  }

  .w-75 {
    width: 75% !important;
  }

  .w-50 {
    width: 50% !important;
  }

  .w-25 {
    width: 25% !important;
  }

  .h-100 {
    height: 100% !important;
  }

  .h-75 {
    height: 75% !important;
  }

  .h-50 {
    height: 50% !important;
  }

  .h-25 {
    height: 25% !important;
  }

  @media (min-width: 36em) {
    .pos-sm-relative {
      position: relative;
    }
    .pos-sm-absolute {
      position: absolute;
    }
    .pos-sm-fixed {
      position: fixed;
    }
    .w-sm-100 {
      width: 100% !important;
    }
    .w-sm-75 {
      width: 75% !important;
    }
    .w-sm-50 {
      width: 50% !important;
    }
    .w-sm-25 {
      width: 25% !important;
    }
    .h-sm-100 {
      height: 100% !important;
    }
    .h-sm-75 {
      height: 75% !important;
    }
    .h-sm-50 {
      height: 50% !important;
    }
    .h-sm-25 {
      height: 25% !important;
    }
  }

  @media (min-width: 48em) {
    .pos-md-relative {
      position: relative;
    }
    .pos-md-absolute {
      position: absolute;
    }
    .pos-md-fixed {
      position: fixed;
    }
    .w-md-100 {
      width: 100% !important;
    }
    .w-md-75 {
      width: 75% !important;
    }
    .w-md-50 {
      width: 50% !important;
    }
    .w-md-25 {
      width: 25% !important;
    }
    .h-md-100 {
      height: 100% !important;
    }
    .h-md-75 {
      height: 75% !important;
    }
    .h-md-50 {
      height: 50% !important;
    }
    .h-md-25 {
      height: 25% !important;
    }
  }

  @media (min-width: 62em) {
    .pos-lg-relative {
      position: relative;
    }
    .pos-lg-absolute {
      position: absolute;
    }
    .pos-lg-fixed {
      position: fixed;
    }
    .w-lg-100 {
      width: 100% !important;
    }
    .w-lg-75 {
      width: 75% !important;
    }
    .w-lg-50 {
      width: 50% !important;
    }
    .w-lg-25 {
      width: 25% !important;
    }
    .h-lg-100 {
      height: 100% !important;
    }
    .h-lg-75 {
      height: 75% !important;
    }
    .h-lg-50 {
      height: 50% !important;
    }
    .h-lg-25 {
      height: 25% !important;
    }
  }

  @media (min-width: 75em) {
    .pos-xl-relative {
      position: relative;
    }
    .pos-xl-absolute {
      position: absolute;
    }
    .pos-xl-fixed {
      position: fixed;
    }
    .w-xl-100 {
      width: 100% !important;
    }
    .w-xl-75 {
      width: 75% !important;
    }
    .w-xl-50 {
      width: 50% !important;
    }
    .w-xl-25 {
      width: 25% !important;
    }
    .h-xl-100 {
      height: 100% !important;
    }
    .h-xl-75 {
      height: 75% !important;
    }
    .h-xl-50 {
      height: 50% !important;
    }
    .h-xl-25 {
      height: 25% !important;
    }
  }

  /*///////////////////////////////////
  // Spacing
  ///////////////////////////////////*/
  .m-0 {
    margin: 0 !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mr-0 {
    margin-right: 0 !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .ml-0 {
    margin-left: 0 !important;
  }

  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1 {
    margin: 0.25rem !important;
  }

  .mt-1 {
    margin-top: 0.25rem !important;
  }

  .mr-1 {
    margin-right: 0.25rem !important;
  }

  .mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-1 {
    margin-left: 0.25rem !important;
  }

  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .m-2 {
    margin: 0.5rem !important;
  }

  .mt-2 {
    margin-top: 0.5rem !important;
  }

  .mr-2 {
    margin-right: 0.5rem !important;
  }

  .mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-2 {
    margin-left: 0.5rem !important;
  }

  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .m-3 {
    margin: 1rem !important;
  }

  .mt-3 {
    margin-top: 1rem !important;
  }

  .mr-3 {
    margin-right: 1rem !important;
  }

  .mb-3 {
    margin-bottom: 1rem !important;
  }

  .ml-3 {
    margin-left: 1rem !important;
  }

  .mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-4 {
    margin: 1.8rem !important;
  }

  .mt-4 {
    margin-top: 1.8rem !important;
  }

  .mr-4 {
    margin-right: 1.8rem !important;
  }

  .mb-4 {
    margin-bottom: 1.8rem !important;
  }

  .ml-4 {
    margin-left: 1.8rem !important;
  }

  .mx-4 {
    margin-left: 1.8rem !important;
    margin-right: 1.8rem !important;
  }

  .my-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
  }

  .m-5 {
    margin: 3rem !important;
  }

  .mt-5 {
    margin-top: 3rem !important;
  }

  .mr-5 {
    margin-right: 3rem !important;
  }

  .mb-5 {
    margin-bottom: 3rem !important;
  }

  .ml-5 {
    margin-left: 3rem !important;
  }

  .mx-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .m-6 {
    margin: 4rem !important;
  }

  .mt-6 {
    margin-top: 4rem !important;
  }

  .mr-6 {
    margin-right: 4rem !important;
  }

  .mb-6 {
    margin-bottom: 4rem !important;
  }

  .ml-6 {
    margin-left: 4rem !important;
  }

  .mx-6 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .my-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .m-7 {
    margin: 5rem !important;
  }

  .mt-7 {
    margin-top: 5rem !important;
  }

  .mr-7 {
    margin-right: 5rem !important;
  }

  .mb-7 {
    margin-bottom: 5rem !important;
  }

  .ml-7 {
    margin-left: 5rem !important;
  }

  .mx-7 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .my-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .m-8 {
    margin: 7.5rem !important;
  }

  .mt-8 {
    margin-top: 7.5rem !important;
  }

  .mr-8 {
    margin-right: 7.5rem !important;
  }

  .mb-8 {
    margin-bottom: 7.5rem !important;
  }

  .ml-8 {
    margin-left: 7.5rem !important;
  }

  .mx-8 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .my-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .m-9 {
    margin: 10rem !important;
  }

  .mt-9 {
    margin-top: 10rem !important;
  }

  .mr-9 {
    margin-right: 10rem !important;
  }

  .mb-9 {
    margin-bottom: 10rem !important;
  }

  .ml-9 {
    margin-left: 10rem !important;
  }

  .mx-9 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .my-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .m-10 {
    margin: 12.5rem !important;
  }

  .mt-10 {
    margin-top: 12.5rem !important;
  }

  .mr-10 {
    margin-right: 12.5rem !important;
  }

  .mb-10 {
    margin-bottom: 12.5rem !important;
  }

  .ml-10 {
    margin-left: 12.5rem !important;
  }

  .mx-10 {
    margin-left: 12.5rem !important;
    margin-right: 12.5rem !important;
  }

  .my-10 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }

  .m-11 {
    margin: 15rem !important;
  }

  .mt-11 {
    margin-top: 15rem !important;
  }

  .mr-11 {
    margin-right: 15rem !important;
  }

  .mb-11 {
    margin-bottom: 15rem !important;
  }

  .ml-11 {
    margin-left: 15rem !important;
  }

  .mx-11 {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .my-11 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  @media (max-width: 35.99em) {
    .m-xs-0 {
      margin: 0 !important;
    }
    .mt-xs-0 {
      margin-top: 0 !important;
    }
    .mr-xs-0 {
      margin-right: 0 !important;
    }
    .mb-xs-0 {
      margin-bottom: 0 !important;
    }
    .ml-xs-0 {
      margin-left: 0 !important;
    }
    .mx-xs-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .my-xs-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .m-xs-1 {
      margin: 0.25rem !important;
    }
    .mt-xs-1 {
      margin-top: 0.25rem !important;
    }
    .mr-xs-1 {
      margin-right: 0.25rem !important;
    }
    .mb-xs-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-xs-1 {
      margin-left: 0.25rem !important;
    }
    .mx-xs-1 {
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
    }
    .my-xs-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .m-xs-2 {
      margin: 0.5rem !important;
    }
    .mt-xs-2 {
      margin-top: 0.5rem !important;
    }
    .mr-xs-2 {
      margin-right: 0.5rem !important;
    }
    .mb-xs-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-xs-2 {
      margin-left: 0.5rem !important;
    }
    .mx-xs-2 {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
    .my-xs-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .m-xs-3 {
      margin: 1rem !important;
    }
    .mt-xs-3 {
      margin-top: 1rem !important;
    }
    .mr-xs-3 {
      margin-right: 1rem !important;
    }
    .mb-xs-3 {
      margin-bottom: 1rem !important;
    }
    .ml-xs-3 {
      margin-left: 1rem !important;
    }
    .mx-xs-3 {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
    .my-xs-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .m-xs-4 {
      margin: 1.8rem !important;
    }
    .mt-xs-4 {
      margin-top: 1.8rem !important;
    }
    .mr-xs-4 {
      margin-right: 1.8rem !important;
    }
    .mb-xs-4 {
      margin-bottom: 1.8rem !important;
    }
    .ml-xs-4 {
      margin-left: 1.8rem !important;
    }
    .mx-xs-4 {
      margin-left: 1.8rem !important;
      margin-right: 1.8rem !important;
    }
    .my-xs-4 {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
    }
    .m-xs-5 {
      margin: 3rem !important;
    }
    .mt-xs-5 {
      margin-top: 3rem !important;
    }
    .mr-xs-5 {
      margin-right: 3rem !important;
    }
    .mb-xs-5 {
      margin-bottom: 3rem !important;
    }
    .ml-xs-5 {
      margin-left: 3rem !important;
    }
    .mx-xs-5 {
      margin-left: 3rem !important;
      margin-right: 3rem !important;
    }
    .my-xs-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .m-xs-6 {
      margin: 4rem !important;
    }
    .mt-xs-6 {
      margin-top: 4rem !important;
    }
    .mr-xs-6 {
      margin-right: 4rem !important;
    }
    .mb-xs-6 {
      margin-bottom: 4rem !important;
    }
    .ml-xs-6 {
      margin-left: 4rem !important;
    }
    .mx-xs-6 {
      margin-left: 4rem !important;
      margin-right: 4rem !important;
    }
    .my-xs-6 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .m-xs-7 {
      margin: 5rem !important;
    }
    .mt-xs-7 {
      margin-top: 5rem !important;
    }
    .mr-xs-7 {
      margin-right: 5rem !important;
    }
    .mb-xs-7 {
      margin-bottom: 5rem !important;
    }
    .ml-xs-7 {
      margin-left: 5rem !important;
    }
    .mx-xs-7 {
      margin-left: 5rem !important;
      margin-right: 5rem !important;
    }
    .my-xs-7 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .m-xs-8 {
      margin: 7.5rem !important;
    }
    .mt-xs-8 {
      margin-top: 7.5rem !important;
    }
    .mr-xs-8 {
      margin-right: 7.5rem !important;
    }
    .mb-xs-8 {
      margin-bottom: 7.5rem !important;
    }
    .ml-xs-8 {
      margin-left: 7.5rem !important;
    }
    .mx-xs-8 {
      margin-left: 7.5rem !important;
      margin-right: 7.5rem !important;
    }
    .my-xs-8 {
      margin-top: 7.5rem !important;
      margin-bottom: 7.5rem !important;
    }
    .m-xs-9 {
      margin: 10rem !important;
    }
    .mt-xs-9 {
      margin-top: 10rem !important;
    }
    .mr-xs-9 {
      margin-right: 10rem !important;
    }
    .mb-xs-9 {
      margin-bottom: 10rem !important;
    }
    .ml-xs-9 {
      margin-left: 10rem !important;
    }
    .mx-xs-9 {
      margin-left: 10rem !important;
      margin-right: 10rem !important;
    }
    .my-xs-9 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .m-xs-10 {
      margin: 12.5rem !important;
    }
    .mt-xs-10 {
      margin-top: 12.5rem !important;
    }
    .mr-xs-10 {
      margin-right: 12.5rem !important;
    }
    .mb-xs-10 {
      margin-bottom: 12.5rem !important;
    }
    .ml-xs-10 {
      margin-left: 12.5rem !important;
    }
    .mx-xs-10 {
      margin-left: 12.5rem !important;
      margin-right: 12.5rem !important;
    }
    .my-xs-10 {
      margin-top: 12.5rem !important;
      margin-bottom: 12.5rem !important;
    }
    .m-xs-11 {
      margin: 15rem !important;
    }
    .mt-xs-11 {
      margin-top: 15rem !important;
    }
    .mr-xs-11 {
      margin-right: 15rem !important;
    }
    .mb-xs-11 {
      margin-bottom: 15rem !important;
    }
    .ml-xs-11 {
      margin-left: 15rem !important;
    }
    .mx-xs-11 {
      margin-left: 15rem !important;
      margin-right: 15rem !important;
    }
    .my-xs-11 {
      margin-top: 15rem !important;
      margin-bottom: 15rem !important;
    }
  }

  @media (min-width: 36em) {
    .m-sm-0 {
      margin: 0 !important;
    }
    .mt-sm-0 {
      margin-top: 0 !important;
    }
    .mr-sm-0 {
      margin-right: 0 !important;
    }
    .mb-sm-0 {
      margin-bottom: 0 !important;
    }
    .ml-sm-0 {
      margin-left: 0 !important;
    }
    .mx-sm-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .m-sm-1 {
      margin: 0.25rem !important;
    }
    .mt-sm-1 {
      margin-top: 0.25rem !important;
    }
    .mr-sm-1 {
      margin-right: 0.25rem !important;
    }
    .mb-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-sm-1 {
      margin-left: 0.25rem !important;
    }
    .mx-sm-1 {
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
    }
    .my-sm-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .m-sm-2 {
      margin: 0.5rem !important;
    }
    .mt-sm-2 {
      margin-top: 0.5rem !important;
    }
    .mr-sm-2 {
      margin-right: 0.5rem !important;
    }
    .mb-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-sm-2 {
      margin-left: 0.5rem !important;
    }
    .mx-sm-2 {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
    .my-sm-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .m-sm-3 {
      margin: 1rem !important;
    }
    .mt-sm-3 {
      margin-top: 1rem !important;
    }
    .mr-sm-3 {
      margin-right: 1rem !important;
    }
    .mb-sm-3 {
      margin-bottom: 1rem !important;
    }
    .ml-sm-3 {
      margin-left: 1rem !important;
    }
    .mx-sm-3 {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
    .my-sm-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .m-sm-4 {
      margin: 1.8rem !important;
    }
    .mt-sm-4 {
      margin-top: 1.8rem !important;
    }
    .mr-sm-4 {
      margin-right: 1.8rem !important;
    }
    .mb-sm-4 {
      margin-bottom: 1.8rem !important;
    }
    .ml-sm-4 {
      margin-left: 1.8rem !important;
    }
    .mx-sm-4 {
      margin-left: 1.8rem !important;
      margin-right: 1.8rem !important;
    }
    .my-sm-4 {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
    }
    .m-sm-5 {
      margin: 3rem !important;
    }
    .mt-sm-5 {
      margin-top: 3rem !important;
    }
    .mr-sm-5 {
      margin-right: 3rem !important;
    }
    .mb-sm-5 {
      margin-bottom: 3rem !important;
    }
    .ml-sm-5 {
      margin-left: 3rem !important;
    }
    .mx-sm-5 {
      margin-left: 3rem !important;
      margin-right: 3rem !important;
    }
    .my-sm-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .m-sm-6 {
      margin: 4rem !important;
    }
    .mt-sm-6 {
      margin-top: 4rem !important;
    }
    .mr-sm-6 {
      margin-right: 4rem !important;
    }
    .mb-sm-6 {
      margin-bottom: 4rem !important;
    }
    .ml-sm-6 {
      margin-left: 4rem !important;
    }
    .mx-sm-6 {
      margin-left: 4rem !important;
      margin-right: 4rem !important;
    }
    .my-sm-6 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .m-sm-7 {
      margin: 5rem !important;
    }
    .mt-sm-7 {
      margin-top: 5rem !important;
    }
    .mr-sm-7 {
      margin-right: 5rem !important;
    }
    .mb-sm-7 {
      margin-bottom: 5rem !important;
    }
    .ml-sm-7 {
      margin-left: 5rem !important;
    }
    .mx-sm-7 {
      margin-left: 5rem !important;
      margin-right: 5rem !important;
    }
    .my-sm-7 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .m-sm-8 {
      margin: 7.5rem !important;
    }
    .mt-sm-8 {
      margin-top: 7.5rem !important;
    }
    .mr-sm-8 {
      margin-right: 7.5rem !important;
    }
    .mb-sm-8 {
      margin-bottom: 7.5rem !important;
    }
    .ml-sm-8 {
      margin-left: 7.5rem !important;
    }
    .mx-sm-8 {
      margin-left: 7.5rem !important;
      margin-right: 7.5rem !important;
    }
    .my-sm-8 {
      margin-top: 7.5rem !important;
      margin-bottom: 7.5rem !important;
    }
    .m-sm-9 {
      margin: 10rem !important;
    }
    .mt-sm-9 {
      margin-top: 10rem !important;
    }
    .mr-sm-9 {
      margin-right: 10rem !important;
    }
    .mb-sm-9 {
      margin-bottom: 10rem !important;
    }
    .ml-sm-9 {
      margin-left: 10rem !important;
    }
    .mx-sm-9 {
      margin-left: 10rem !important;
      margin-right: 10rem !important;
    }
    .my-sm-9 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .m-sm-10 {
      margin: 12.5rem !important;
    }
    .mt-sm-10 {
      margin-top: 12.5rem !important;
    }
    .mr-sm-10 {
      margin-right: 12.5rem !important;
    }
    .mb-sm-10 {
      margin-bottom: 12.5rem !important;
    }
    .ml-sm-10 {
      margin-left: 12.5rem !important;
    }
    .mx-sm-10 {
      margin-left: 12.5rem !important;
      margin-right: 12.5rem !important;
    }
    .my-sm-10 {
      margin-top: 12.5rem !important;
      margin-bottom: 12.5rem !important;
    }
    .m-sm-11 {
      margin: 15rem !important;
    }
    .mt-sm-11 {
      margin-top: 15rem !important;
    }
    .mr-sm-11 {
      margin-right: 15rem !important;
    }
    .mb-sm-11 {
      margin-bottom: 15rem !important;
    }
    .ml-sm-11 {
      margin-left: 15rem !important;
    }
    .mx-sm-11 {
      margin-left: 15rem !important;
      margin-right: 15rem !important;
    }
    .my-sm-11 {
      margin-top: 15rem !important;
      margin-bottom: 15rem !important;
    }
  }

  @media (min-width: 48em) {
    .m-md-0 {
      margin: 0 !important;
    }
    .mt-md-0 {
      margin-top: 0 !important;
    }
    .mr-md-0 {
      margin-right: 0 !important;
    }
    .mb-md-0 {
      margin-bottom: 0 !important;
    }
    .ml-md-0 {
      margin-left: 0 !important;
    }
    .mx-md-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .m-md-1 {
      margin: 0.25rem !important;
    }
    .mt-md-1 {
      margin-top: 0.25rem !important;
    }
    .mr-md-1 {
      margin-right: 0.25rem !important;
    }
    .mb-md-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-md-1 {
      margin-left: 0.25rem !important;
    }
    .mx-md-1 {
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
    }
    .my-md-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .m-md-2 {
      margin: 0.5rem !important;
    }
    .mt-md-2 {
      margin-top: 0.5rem !important;
    }
    .mr-md-2 {
      margin-right: 0.5rem !important;
    }
    .mb-md-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-md-2 {
      margin-left: 0.5rem !important;
    }
    .mx-md-2 {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
    .my-md-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .m-md-3 {
      margin: 1rem !important;
    }
    .mt-md-3 {
      margin-top: 1rem !important;
    }
    .mr-md-3 {
      margin-right: 1rem !important;
    }
    .mb-md-3 {
      margin-bottom: 1rem !important;
    }
    .ml-md-3 {
      margin-left: 1rem !important;
    }
    .mx-md-3 {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
    .my-md-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .m-md-4 {
      margin: 1.8rem !important;
    }
    .mt-md-4 {
      margin-top: 1.8rem !important;
    }
    .mr-md-4 {
      margin-right: 1.8rem !important;
    }
    .mb-md-4 {
      margin-bottom: 1.8rem !important;
    }
    .ml-md-4 {
      margin-left: 1.8rem !important;
    }
    .mx-md-4 {
      margin-left: 1.8rem !important;
      margin-right: 1.8rem !important;
    }
    .my-md-4 {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
    }
    .m-md-5 {
      margin: 3rem !important;
    }
    .mt-md-5 {
      margin-top: 3rem !important;
    }
    .mr-md-5 {
      margin-right: 3rem !important;
    }
    .mb-md-5 {
      margin-bottom: 3rem !important;
    }
    .ml-md-5 {
      margin-left: 3rem !important;
    }
    .mx-md-5 {
      margin-left: 3rem !important;
      margin-right: 3rem !important;
    }
    .my-md-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .m-md-6 {
      margin: 4rem !important;
    }
    .mt-md-6 {
      margin-top: 4rem !important;
    }
    .mr-md-6 {
      margin-right: 4rem !important;
    }
    .mb-md-6 {
      margin-bottom: 4rem !important;
    }
    .ml-md-6 {
      margin-left: 4rem !important;
    }
    .mx-md-6 {
      margin-left: 4rem !important;
      margin-right: 4rem !important;
    }
    .my-md-6 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .m-md-7 {
      margin: 5rem !important;
    }
    .mt-md-7 {
      margin-top: 5rem !important;
    }
    .mr-md-7 {
      margin-right: 5rem !important;
    }
    .mb-md-7 {
      margin-bottom: 5rem !important;
    }
    .ml-md-7 {
      margin-left: 5rem !important;
    }
    .mx-md-7 {
      margin-left: 5rem !important;
      margin-right: 5rem !important;
    }
    .my-md-7 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .m-md-8 {
      margin: 7.5rem !important;
    }
    .mt-md-8 {
      margin-top: 7.5rem !important;
    }
    .mr-md-8 {
      margin-right: 7.5rem !important;
    }
    .mb-md-8 {
      margin-bottom: 7.5rem !important;
    }
    .ml-md-8 {
      margin-left: 7.5rem !important;
    }
    .mx-md-8 {
      margin-left: 7.5rem !important;
      margin-right: 7.5rem !important;
    }
    .my-md-8 {
      margin-top: 7.5rem !important;
      margin-bottom: 7.5rem !important;
    }
    .m-md-9 {
      margin: 10rem !important;
    }
    .mt-md-9 {
      margin-top: 10rem !important;
    }
    .mr-md-9 {
      margin-right: 10rem !important;
    }
    .mb-md-9 {
      margin-bottom: 10rem !important;
    }
    .ml-md-9 {
      margin-left: 10rem !important;
    }
    .mx-md-9 {
      margin-left: 10rem !important;
      margin-right: 10rem !important;
    }
    .my-md-9 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .m-md-10 {
      margin: 12.5rem !important;
    }
    .mt-md-10 {
      margin-top: 12.5rem !important;
    }
    .mr-md-10 {
      margin-right: 12.5rem !important;
    }
    .mb-md-10 {
      margin-bottom: 12.5rem !important;
    }
    .ml-md-10 {
      margin-left: 12.5rem !important;
    }
    .mx-md-10 {
      margin-left: 12.5rem !important;
      margin-right: 12.5rem !important;
    }
    .my-md-10 {
      margin-top: 12.5rem !important;
      margin-bottom: 12.5rem !important;
    }
    .m-md-11 {
      margin: 15rem !important;
    }
    .mt-md-11 {
      margin-top: 15rem !important;
    }
    .mr-md-11 {
      margin-right: 15rem !important;
    }
    .mb-md-11 {
      margin-bottom: 15rem !important;
    }
    .ml-md-11 {
      margin-left: 15rem !important;
    }
    .mx-md-11 {
      margin-left: 15rem !important;
      margin-right: 15rem !important;
    }
    .my-md-11 {
      margin-top: 15rem !important;
      margin-bottom: 15rem !important;
    }
  }

  @media (min-width: 62em) {
    .m-lg-0 {
      margin: 0 !important;
    }
    .mt-lg-0 {
      margin-top: 0 !important;
    }
    .mr-lg-0 {
      margin-right: 0 !important;
    }
    .mb-lg-0 {
      margin-bottom: 0 !important;
    }
    .ml-lg-0 {
      margin-left: 0 !important;
    }
    .mx-lg-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .m-lg-1 {
      margin: 0.25rem !important;
    }
    .mt-lg-1 {
      margin-top: 0.25rem !important;
    }
    .mr-lg-1 {
      margin-right: 0.25rem !important;
    }
    .mb-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-lg-1 {
      margin-left: 0.25rem !important;
    }
    .mx-lg-1 {
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
    }
    .my-lg-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .m-lg-2 {
      margin: 0.5rem !important;
    }
    .mt-lg-2 {
      margin-top: 0.5rem !important;
    }
    .mr-lg-2 {
      margin-right: 0.5rem !important;
    }
    .mb-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-lg-2 {
      margin-left: 0.5rem !important;
    }
    .mx-lg-2 {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
    .my-lg-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .m-lg-3 {
      margin: 1rem !important;
    }
    .mt-lg-3 {
      margin-top: 1rem !important;
    }
    .mr-lg-3 {
      margin-right: 1rem !important;
    }
    .mb-lg-3 {
      margin-bottom: 1rem !important;
    }
    .ml-lg-3 {
      margin-left: 1rem !important;
    }
    .mx-lg-3 {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
    .my-lg-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .m-lg-4 {
      margin: 1.8rem !important;
    }
    .mt-lg-4 {
      margin-top: 1.8rem !important;
    }
    .mr-lg-4 {
      margin-right: 1.8rem !important;
    }
    .mb-lg-4 {
      margin-bottom: 1.8rem !important;
    }
    .ml-lg-4 {
      margin-left: 1.8rem !important;
    }
    .mx-lg-4 {
      margin-left: 1.8rem !important;
      margin-right: 1.8rem !important;
    }
    .my-lg-4 {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
    }
    .m-lg-5 {
      margin: 3rem !important;
    }
    .mt-lg-5 {
      margin-top: 3rem !important;
    }
    .mr-lg-5 {
      margin-right: 3rem !important;
    }
    .mb-lg-5 {
      margin-bottom: 3rem !important;
    }
    .ml-lg-5 {
      margin-left: 3rem !important;
    }
    .mx-lg-5 {
      margin-left: 3rem !important;
      margin-right: 3rem !important;
    }
    .my-lg-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .m-lg-6 {
      margin: 4rem !important;
    }
    .mt-lg-6 {
      margin-top: 4rem !important;
    }
    .mr-lg-6 {
      margin-right: 4rem !important;
    }
    .mb-lg-6 {
      margin-bottom: 4rem !important;
    }
    .ml-lg-6 {
      margin-left: 4rem !important;
    }
    .mx-lg-6 {
      margin-left: 4rem !important;
      margin-right: 4rem !important;
    }
    .my-lg-6 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .m-lg-7 {
      margin: 5rem !important;
    }
    .mt-lg-7 {
      margin-top: 5rem !important;
    }
    .mr-lg-7 {
      margin-right: 5rem !important;
    }
    .mb-lg-7 {
      margin-bottom: 5rem !important;
    }
    .ml-lg-7 {
      margin-left: 5rem !important;
    }
    .mx-lg-7 {
      margin-left: 5rem !important;
      margin-right: 5rem !important;
    }
    .my-lg-7 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .m-lg-8 {
      margin: 7.5rem !important;
    }
    .mt-lg-8 {
      margin-top: 7.5rem !important;
    }
    .mr-lg-8 {
      margin-right: 7.5rem !important;
    }
    .mb-lg-8 {
      margin-bottom: 7.5rem !important;
    }
    .ml-lg-8 {
      margin-left: 7.5rem !important;
    }
    .mx-lg-8 {
      margin-left: 7.5rem !important;
      margin-right: 7.5rem !important;
    }
    .my-lg-8 {
      margin-top: 7.5rem !important;
      margin-bottom: 7.5rem !important;
    }
    .m-lg-9 {
      margin: 10rem !important;
    }
    .mt-lg-9 {
      margin-top: 10rem !important;
    }
    .mr-lg-9 {
      margin-right: 10rem !important;
    }
    .mb-lg-9 {
      margin-bottom: 10rem !important;
    }
    .ml-lg-9 {
      margin-left: 10rem !important;
    }
    .mx-lg-9 {
      margin-left: 10rem !important;
      margin-right: 10rem !important;
    }
    .my-lg-9 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .m-lg-10 {
      margin: 12.5rem !important;
    }
    .mt-lg-10 {
      margin-top: 12.5rem !important;
    }
    .mr-lg-10 {
      margin-right: 12.5rem !important;
    }
    .mb-lg-10 {
      margin-bottom: 12.5rem !important;
    }
    .ml-lg-10 {
      margin-left: 12.5rem !important;
    }
    .mx-lg-10 {
      margin-left: 12.5rem !important;
      margin-right: 12.5rem !important;
    }
    .my-lg-10 {
      margin-top: 12.5rem !important;
      margin-bottom: 12.5rem !important;
    }
    .m-lg-11 {
      margin: 15rem !important;
    }
    .mt-lg-11 {
      margin-top: 15rem !important;
    }
    .mr-lg-11 {
      margin-right: 15rem !important;
    }
    .mb-lg-11 {
      margin-bottom: 15rem !important;
    }
    .ml-lg-11 {
      margin-left: 15rem !important;
    }
    .mx-lg-11 {
      margin-left: 15rem !important;
      margin-right: 15rem !important;
    }
    .my-lg-11 {
      margin-top: 15rem !important;
      margin-bottom: 15rem !important;
    }
  }

  @media (min-width: 75em) {
    .m-xl-0 {
      margin: 0 !important;
    }
    .mt-xl-0 {
      margin-top: 0 !important;
    }
    .mr-xl-0 {
      margin-right: 0 !important;
    }
    .mb-xl-0 {
      margin-bottom: 0 !important;
    }
    .ml-xl-0 {
      margin-left: 0 !important;
    }
    .mx-xl-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .m-xl-1 {
      margin: 0.25rem !important;
    }
    .mt-xl-1 {
      margin-top: 0.25rem !important;
    }
    .mr-xl-1 {
      margin-right: 0.25rem !important;
    }
    .mb-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-xl-1 {
      margin-left: 0.25rem !important;
    }
    .mx-xl-1 {
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
    }
    .my-xl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .m-xl-2 {
      margin: 0.5rem !important;
    }
    .mt-xl-2 {
      margin-top: 0.5rem !important;
    }
    .mr-xl-2 {
      margin-right: 0.5rem !important;
    }
    .mb-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-xl-2 {
      margin-left: 0.5rem !important;
    }
    .mx-xl-2 {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
    .my-xl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .m-xl-3 {
      margin: 1rem !important;
    }
    .mt-xl-3 {
      margin-top: 1rem !important;
    }
    .mr-xl-3 {
      margin-right: 1rem !important;
    }
    .mb-xl-3 {
      margin-bottom: 1rem !important;
    }
    .ml-xl-3 {
      margin-left: 1rem !important;
    }
    .mx-xl-3 {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
    .my-xl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .m-xl-4 {
      margin: 1.8rem !important;
    }
    .mt-xl-4 {
      margin-top: 1.8rem !important;
    }
    .mr-xl-4 {
      margin-right: 1.8rem !important;
    }
    .mb-xl-4 {
      margin-bottom: 1.8rem !important;
    }
    .ml-xl-4 {
      margin-left: 1.8rem !important;
    }
    .mx-xl-4 {
      margin-left: 1.8rem !important;
      margin-right: 1.8rem !important;
    }
    .my-xl-4 {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
    }
    .m-xl-5 {
      margin: 3rem !important;
    }
    .mt-xl-5 {
      margin-top: 3rem !important;
    }
    .mr-xl-5 {
      margin-right: 3rem !important;
    }
    .mb-xl-5 {
      margin-bottom: 3rem !important;
    }
    .ml-xl-5 {
      margin-left: 3rem !important;
    }
    .mx-xl-5 {
      margin-left: 3rem !important;
      margin-right: 3rem !important;
    }
    .my-xl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .m-xl-6 {
      margin: 4rem !important;
    }
    .mt-xl-6 {
      margin-top: 4rem !important;
    }
    .mr-xl-6 {
      margin-right: 4rem !important;
    }
    .mb-xl-6 {
      margin-bottom: 4rem !important;
    }
    .ml-xl-6 {
      margin-left: 4rem !important;
    }
    .mx-xl-6 {
      margin-left: 4rem !important;
      margin-right: 4rem !important;
    }
    .my-xl-6 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .m-xl-7 {
      margin: 5rem !important;
    }
    .mt-xl-7 {
      margin-top: 5rem !important;
    }
    .mr-xl-7 {
      margin-right: 5rem !important;
    }
    .mb-xl-7 {
      margin-bottom: 5rem !important;
    }
    .ml-xl-7 {
      margin-left: 5rem !important;
    }
    .mx-xl-7 {
      margin-left: 5rem !important;
      margin-right: 5rem !important;
    }
    .my-xl-7 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .m-xl-8 {
      margin: 7.5rem !important;
    }
    .mt-xl-8 {
      margin-top: 7.5rem !important;
    }
    .mr-xl-8 {
      margin-right: 7.5rem !important;
    }
    .mb-xl-8 {
      margin-bottom: 7.5rem !important;
    }
    .ml-xl-8 {
      margin-left: 7.5rem !important;
    }
    .mx-xl-8 {
      margin-left: 7.5rem !important;
      margin-right: 7.5rem !important;
    }
    .my-xl-8 {
      margin-top: 7.5rem !important;
      margin-bottom: 7.5rem !important;
    }
    .m-xl-9 {
      margin: 10rem !important;
    }
    .mt-xl-9 {
      margin-top: 10rem !important;
    }
    .mr-xl-9 {
      margin-right: 10rem !important;
    }
    .mb-xl-9 {
      margin-bottom: 10rem !important;
    }
    .ml-xl-9 {
      margin-left: 10rem !important;
    }
    .mx-xl-9 {
      margin-left: 10rem !important;
      margin-right: 10rem !important;
    }
    .my-xl-9 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .m-xl-10 {
      margin: 12.5rem !important;
    }
    .mt-xl-10 {
      margin-top: 12.5rem !important;
    }
    .mr-xl-10 {
      margin-right: 12.5rem !important;
    }
    .mb-xl-10 {
      margin-bottom: 12.5rem !important;
    }
    .ml-xl-10 {
      margin-left: 12.5rem !important;
    }
    .mx-xl-10 {
      margin-left: 12.5rem !important;
      margin-right: 12.5rem !important;
    }
    .my-xl-10 {
      margin-top: 12.5rem !important;
      margin-bottom: 12.5rem !important;
    }
    .m-xl-11 {
      margin: 15rem !important;
    }
    .mt-xl-11 {
      margin-top: 15rem !important;
    }
    .mr-xl-11 {
      margin-right: 15rem !important;
    }
    .mb-xl-11 {
      margin-bottom: 15rem !important;
    }
    .ml-xl-11 {
      margin-left: 15rem !important;
    }
    .mx-xl-11 {
      margin-left: 15rem !important;
      margin-right: 15rem !important;
    }
    .my-xl-11 {
      margin-top: 15rem !important;
      margin-bottom: 15rem !important;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pt-0 {
    padding-top: 0 !important;
  }

  .pr-0 {
    padding-right: 0 !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }

  .pl-0 {
    padding-left: 0 !important;
  }

  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1 {
    padding: 0.25rem !important;
  }

  .pt-1 {
    padding-top: 0.25rem !important;
  }

  .pr-1 {
    padding-right: 0.25rem !important;
  }

  .pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-1 {
    padding-left: 0.25rem !important;
  }

  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .p-2 {
    padding: 0.5rem !important;
  }

  .pt-2 {
    padding-top: 0.5rem !important;
  }

  .pr-2 {
    padding-right: 0.5rem !important;
  }

  .pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-2 {
    padding-left: 0.5rem !important;
  }

  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .p-3 {
    padding: 1rem !important;
  }

  .pt-3 {
    padding-top: 1rem !important;
  }

  .pr-3 {
    padding-right: 1rem !important;
  }

  .pb-3 {
    padding-bottom: 1rem !important;
  }

  .pl-3 {
    padding-left: 1rem !important;
  }

  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-4 {
    padding: 1.8rem !important;
  }

  .pt-4 {
    padding-top: 1.8rem !important;
  }

  .pr-4 {
    padding-right: 1.8rem !important;
  }

  .pb-4 {
    padding-bottom: 1.8rem !important;
  }

  .pl-4 {
    padding-left: 1.8rem !important;
  }

  .px-4 {
    padding-left: 1.8rem !important;
    padding-right: 1.8rem !important;
  }

  .py-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
  }

  .p-5 {
    padding: 3rem !important;
  }

  .pt-5 {
    padding-top: 3rem !important;
  }

  .pr-5 {
    padding-right: 3rem !important;
  }

  .pb-5 {
    padding-bottom: 3rem !important;
  }

  .pl-5 {
    padding-left: 3rem !important;
  }

  .px-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-6 {
    padding: 4rem !important;
  }

  .pt-6 {
    padding-top: 4rem !important;
  }

  .pr-6 {
    padding-right: 4rem !important;
  }

  .pb-6 {
    padding-bottom: 4rem !important;
  }

  .pl-6 {
    padding-left: 4rem !important;
  }

  .px-6 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .py-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .p-7 {
    padding: 5rem !important;
  }

  .pt-7 {
    padding-top: 5rem !important;
  }

  .pr-7 {
    padding-right: 5rem !important;
  }

  .pb-7 {
    padding-bottom: 5rem !important;
  }

  .pl-7 {
    padding-left: 5rem !important;
  }

  .px-7 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .p-8 {
    padding: 7.5rem !important;
  }

  .pt-8 {
    padding-top: 7.5rem !important;
  }

  .pr-8 {
    padding-right: 7.5rem !important;
  }

  .pb-8 {
    padding-bottom: 7.5rem !important;
  }

  .pl-8 {
    padding-left: 7.5rem !important;
  }

  .px-8 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .py-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .p-9 {
    padding: 10rem !important;
  }

  .pt-9 {
    padding-top: 10rem !important;
  }

  .pr-9 {
    padding-right: 10rem !important;
  }

  .pb-9 {
    padding-bottom: 10rem !important;
  }

  .pl-9 {
    padding-left: 10rem !important;
  }

  .px-9 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .py-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .p-10 {
    padding: 12.5rem !important;
  }

  .pt-10 {
    padding-top: 12.5rem !important;
  }

  .pr-10 {
    padding-right: 12.5rem !important;
  }

  .pb-10 {
    padding-bottom: 12.5rem !important;
  }

  .pl-10 {
    padding-left: 12.5rem !important;
  }

  .px-10 {
    padding-left: 12.5rem !important;
    padding-right: 12.5rem !important;
  }

  .py-10 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  .p-11 {
    padding: 15rem !important;
  }

  .pt-11 {
    padding-top: 15rem !important;
  }

  .pr-11 {
    padding-right: 15rem !important;
  }

  .pb-11 {
    padding-bottom: 15rem !important;
  }

  .pl-11 {
    padding-left: 15rem !important;
  }

  .px-11 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .py-11 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  @media (max-width: 35.99em) {
    .p-xs-0 {
      padding: 0 !important;
    }
    .pt-xs-0 {
      padding-top: 0 !important;
    }
    .pr-xs-0 {
      padding-right: 0 !important;
    }
    .pb-xs-0 {
      padding-bottom: 0 !important;
    }
    .pl-xs-0 {
      padding-left: 0 !important;
    }
    .px-xs-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .py-xs-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .p-xs-1 {
      padding: 0.25rem !important;
    }
    .pt-xs-1 {
      padding-top: 0.25rem !important;
    }
    .pr-xs-1 {
      padding-right: 0.25rem !important;
    }
    .pb-xs-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-xs-1 {
      padding-left: 0.25rem !important;
    }
    .px-xs-1 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
    .py-xs-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .p-xs-2 {
      padding: 0.5rem !important;
    }
    .pt-xs-2 {
      padding-top: 0.5rem !important;
    }
    .pr-xs-2 {
      padding-right: 0.5rem !important;
    }
    .pb-xs-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-xs-2 {
      padding-left: 0.5rem !important;
    }
    .px-xs-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
    .py-xs-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .p-xs-3 {
      padding: 1rem !important;
    }
    .pt-xs-3 {
      padding-top: 1rem !important;
    }
    .pr-xs-3 {
      padding-right: 1rem !important;
    }
    .pb-xs-3 {
      padding-bottom: 1rem !important;
    }
    .pl-xs-3 {
      padding-left: 1rem !important;
    }
    .px-xs-3 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .py-xs-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .p-xs-4 {
      padding: 1.8rem !important;
    }
    .pt-xs-4 {
      padding-top: 1.8rem !important;
    }
    .pr-xs-4 {
      padding-right: 1.8rem !important;
    }
    .pb-xs-4 {
      padding-bottom: 1.8rem !important;
    }
    .pl-xs-4 {
      padding-left: 1.8rem !important;
    }
    .px-xs-4 {
      padding-left: 1.8rem !important;
      padding-right: 1.8rem !important;
    }
    .py-xs-4 {
      padding-top: 1.8rem !important;
      padding-bottom: 1.8rem !important;
    }
    .p-xs-5 {
      padding: 3rem !important;
    }
    .pt-xs-5 {
      padding-top: 3rem !important;
    }
    .pr-xs-5 {
      padding-right: 3rem !important;
    }
    .pb-xs-5 {
      padding-bottom: 3rem !important;
    }
    .pl-xs-5 {
      padding-left: 3rem !important;
    }
    .px-xs-5 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
    .py-xs-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .p-xs-6 {
      padding: 4rem !important;
    }
    .pt-xs-6 {
      padding-top: 4rem !important;
    }
    .pr-xs-6 {
      padding-right: 4rem !important;
    }
    .pb-xs-6 {
      padding-bottom: 4rem !important;
    }
    .pl-xs-6 {
      padding-left: 4rem !important;
    }
    .px-xs-6 {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
    .py-xs-6 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .p-xs-7 {
      padding: 5rem !important;
    }
    .pt-xs-7 {
      padding-top: 5rem !important;
    }
    .pr-xs-7 {
      padding-right: 5rem !important;
    }
    .pb-xs-7 {
      padding-bottom: 5rem !important;
    }
    .pl-xs-7 {
      padding-left: 5rem !important;
    }
    .px-xs-7 {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
    .py-xs-7 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .p-xs-8 {
      padding: 7.5rem !important;
    }
    .pt-xs-8 {
      padding-top: 7.5rem !important;
    }
    .pr-xs-8 {
      padding-right: 7.5rem !important;
    }
    .pb-xs-8 {
      padding-bottom: 7.5rem !important;
    }
    .pl-xs-8 {
      padding-left: 7.5rem !important;
    }
    .px-xs-8 {
      padding-left: 7.5rem !important;
      padding-right: 7.5rem !important;
    }
    .py-xs-8 {
      padding-top: 7.5rem !important;
      padding-bottom: 7.5rem !important;
    }
    .p-xs-9 {
      padding: 10rem !important;
    }
    .pt-xs-9 {
      padding-top: 10rem !important;
    }
    .pr-xs-9 {
      padding-right: 10rem !important;
    }
    .pb-xs-9 {
      padding-bottom: 10rem !important;
    }
    .pl-xs-9 {
      padding-left: 10rem !important;
    }
    .px-xs-9 {
      padding-left: 10rem !important;
      padding-right: 10rem !important;
    }
    .py-xs-9 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .p-xs-10 {
      padding: 12.5rem !important;
    }
    .pt-xs-10 {
      padding-top: 12.5rem !important;
    }
    .pr-xs-10 {
      padding-right: 12.5rem !important;
    }
    .pb-xs-10 {
      padding-bottom: 12.5rem !important;
    }
    .pl-xs-10 {
      padding-left: 12.5rem !important;
    }
    .px-xs-10 {
      padding-left: 12.5rem !important;
      padding-right: 12.5rem !important;
    }
    .py-xs-10 {
      padding-top: 12.5rem !important;
      padding-bottom: 12.5rem !important;
    }
    .p-xs-11 {
      padding: 15rem !important;
    }
    .pt-xs-11 {
      padding-top: 15rem !important;
    }
    .pr-xs-11 {
      padding-right: 15rem !important;
    }
    .pb-xs-11 {
      padding-bottom: 15rem !important;
    }
    .pl-xs-11 {
      padding-left: 15rem !important;
    }
    .px-xs-11 {
      padding-left: 15rem !important;
      padding-right: 15rem !important;
    }
    .py-xs-11 {
      padding-top: 15rem !important;
      padding-bottom: 15rem !important;
    }
  }

  @media (min-width: 36em) {
    .p-sm-0 {
      padding: 0 !important;
    }
    .pt-sm-0 {
      padding-top: 0 !important;
    }
    .pr-sm-0 {
      padding-right: 0 !important;
    }
    .pb-sm-0 {
      padding-bottom: 0 !important;
    }
    .pl-sm-0 {
      padding-left: 0 !important;
    }
    .px-sm-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .pt-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pr-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pb-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-sm-1 {
      padding-left: 0.25rem !important;
    }
    .px-sm-1 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
    .py-sm-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .pt-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pr-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pb-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-sm-2 {
      padding-left: 0.5rem !important;
    }
    .px-sm-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
    .py-sm-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 1rem !important;
    }
    .pt-sm-3 {
      padding-top: 1rem !important;
    }
    .pr-sm-3 {
      padding-right: 1rem !important;
    }
    .pb-sm-3 {
      padding-bottom: 1rem !important;
    }
    .pl-sm-3 {
      padding-left: 1rem !important;
    }
    .px-sm-3 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .py-sm-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .p-sm-4 {
      padding: 1.8rem !important;
    }
    .pt-sm-4 {
      padding-top: 1.8rem !important;
    }
    .pr-sm-4 {
      padding-right: 1.8rem !important;
    }
    .pb-sm-4 {
      padding-bottom: 1.8rem !important;
    }
    .pl-sm-4 {
      padding-left: 1.8rem !important;
    }
    .px-sm-4 {
      padding-left: 1.8rem !important;
      padding-right: 1.8rem !important;
    }
    .py-sm-4 {
      padding-top: 1.8rem !important;
      padding-bottom: 1.8rem !important;
    }
    .p-sm-5 {
      padding: 3rem !important;
    }
    .pt-sm-5 {
      padding-top: 3rem !important;
    }
    .pr-sm-5 {
      padding-right: 3rem !important;
    }
    .pb-sm-5 {
      padding-bottom: 3rem !important;
    }
    .pl-sm-5 {
      padding-left: 3rem !important;
    }
    .px-sm-5 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
    .py-sm-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .p-sm-6 {
      padding: 4rem !important;
    }
    .pt-sm-6 {
      padding-top: 4rem !important;
    }
    .pr-sm-6 {
      padding-right: 4rem !important;
    }
    .pb-sm-6 {
      padding-bottom: 4rem !important;
    }
    .pl-sm-6 {
      padding-left: 4rem !important;
    }
    .px-sm-6 {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
    .py-sm-6 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .p-sm-7 {
      padding: 5rem !important;
    }
    .pt-sm-7 {
      padding-top: 5rem !important;
    }
    .pr-sm-7 {
      padding-right: 5rem !important;
    }
    .pb-sm-7 {
      padding-bottom: 5rem !important;
    }
    .pl-sm-7 {
      padding-left: 5rem !important;
    }
    .px-sm-7 {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
    .py-sm-7 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .p-sm-8 {
      padding: 7.5rem !important;
    }
    .pt-sm-8 {
      padding-top: 7.5rem !important;
    }
    .pr-sm-8 {
      padding-right: 7.5rem !important;
    }
    .pb-sm-8 {
      padding-bottom: 7.5rem !important;
    }
    .pl-sm-8 {
      padding-left: 7.5rem !important;
    }
    .px-sm-8 {
      padding-left: 7.5rem !important;
      padding-right: 7.5rem !important;
    }
    .py-sm-8 {
      padding-top: 7.5rem !important;
      padding-bottom: 7.5rem !important;
    }
    .p-sm-9 {
      padding: 10rem !important;
    }
    .pt-sm-9 {
      padding-top: 10rem !important;
    }
    .pr-sm-9 {
      padding-right: 10rem !important;
    }
    .pb-sm-9 {
      padding-bottom: 10rem !important;
    }
    .pl-sm-9 {
      padding-left: 10rem !important;
    }
    .px-sm-9 {
      padding-left: 10rem !important;
      padding-right: 10rem !important;
    }
    .py-sm-9 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .p-sm-10 {
      padding: 12.5rem !important;
    }
    .pt-sm-10 {
      padding-top: 12.5rem !important;
    }
    .pr-sm-10 {
      padding-right: 12.5rem !important;
    }
    .pb-sm-10 {
      padding-bottom: 12.5rem !important;
    }
    .pl-sm-10 {
      padding-left: 12.5rem !important;
    }
    .px-sm-10 {
      padding-left: 12.5rem !important;
      padding-right: 12.5rem !important;
    }
    .py-sm-10 {
      padding-top: 12.5rem !important;
      padding-bottom: 12.5rem !important;
    }
    .p-sm-11 {
      padding: 15rem !important;
    }
    .pt-sm-11 {
      padding-top: 15rem !important;
    }
    .pr-sm-11 {
      padding-right: 15rem !important;
    }
    .pb-sm-11 {
      padding-bottom: 15rem !important;
    }
    .pl-sm-11 {
      padding-left: 15rem !important;
    }
    .px-sm-11 {
      padding-left: 15rem !important;
      padding-right: 15rem !important;
    }
    .py-sm-11 {
      padding-top: 15rem !important;
      padding-bottom: 15rem !important;
    }
  }

  @media (min-width: 48em) {
    .p-md-0 {
      padding: 0 !important;
    }
    .pt-md-0 {
      padding-top: 0 !important;
    }
    .pr-md-0 {
      padding-right: 0 !important;
    }
    .pb-md-0 {
      padding-bottom: 0 !important;
    }
    .pl-md-0 {
      padding-left: 0 !important;
    }
    .px-md-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .pt-md-1 {
      padding-top: 0.25rem !important;
    }
    .pr-md-1 {
      padding-right: 0.25rem !important;
    }
    .pb-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-md-1 {
      padding-left: 0.25rem !important;
    }
    .px-md-1 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
    .py-md-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .pt-md-2 {
      padding-top: 0.5rem !important;
    }
    .pr-md-2 {
      padding-right: 0.5rem !important;
    }
    .pb-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-md-2 {
      padding-left: 0.5rem !important;
    }
    .px-md-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
    .py-md-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .p-md-3 {
      padding: 1rem !important;
    }
    .pt-md-3 {
      padding-top: 1rem !important;
    }
    .pr-md-3 {
      padding-right: 1rem !important;
    }
    .pb-md-3 {
      padding-bottom: 1rem !important;
    }
    .pl-md-3 {
      padding-left: 1rem !important;
    }
    .px-md-3 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .py-md-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .p-md-4 {
      padding: 1.8rem !important;
    }
    .pt-md-4 {
      padding-top: 1.8rem !important;
    }
    .pr-md-4 {
      padding-right: 1.8rem !important;
    }
    .pb-md-4 {
      padding-bottom: 1.8rem !important;
    }
    .pl-md-4 {
      padding-left: 1.8rem !important;
    }
    .px-md-4 {
      padding-left: 1.8rem !important;
      padding-right: 1.8rem !important;
    }
    .py-md-4 {
      padding-top: 1.8rem !important;
      padding-bottom: 1.8rem !important;
    }
    .p-md-5 {
      padding: 3rem !important;
    }
    .pt-md-5 {
      padding-top: 3rem !important;
    }
    .pr-md-5 {
      padding-right: 3rem !important;
    }
    .pb-md-5 {
      padding-bottom: 3rem !important;
    }
    .pl-md-5 {
      padding-left: 3rem !important;
    }
    .px-md-5 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
    .py-md-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .p-md-6 {
      padding: 4rem !important;
    }
    .pt-md-6 {
      padding-top: 4rem !important;
    }
    .pr-md-6 {
      padding-right: 4rem !important;
    }
    .pb-md-6 {
      padding-bottom: 4rem !important;
    }
    .pl-md-6 {
      padding-left: 4rem !important;
    }
    .px-md-6 {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
    .py-md-6 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .p-md-7 {
      padding: 5rem !important;
    }
    .pt-md-7 {
      padding-top: 5rem !important;
    }
    .pr-md-7 {
      padding-right: 5rem !important;
    }
    .pb-md-7 {
      padding-bottom: 5rem !important;
    }
    .pl-md-7 {
      padding-left: 5rem !important;
    }
    .px-md-7 {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
    .py-md-7 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .p-md-8 {
      padding: 7.5rem !important;
    }
    .pt-md-8 {
      padding-top: 7.5rem !important;
    }
    .pr-md-8 {
      padding-right: 7.5rem !important;
    }
    .pb-md-8 {
      padding-bottom: 7.5rem !important;
    }
    .pl-md-8 {
      padding-left: 7.5rem !important;
    }
    .px-md-8 {
      padding-left: 7.5rem !important;
      padding-right: 7.5rem !important;
    }
    .py-md-8 {
      padding-top: 7.5rem !important;
      padding-bottom: 7.5rem !important;
    }
    .p-md-9 {
      padding: 10rem !important;
    }
    .pt-md-9 {
      padding-top: 10rem !important;
    }
    .pr-md-9 {
      padding-right: 10rem !important;
    }
    .pb-md-9 {
      padding-bottom: 10rem !important;
    }
    .pl-md-9 {
      padding-left: 10rem !important;
    }
    .px-md-9 {
      padding-left: 10rem !important;
      padding-right: 10rem !important;
    }
    .py-md-9 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .p-md-10 {
      padding: 12.5rem !important;
    }
    .pt-md-10 {
      padding-top: 12.5rem !important;
    }
    .pr-md-10 {
      padding-right: 12.5rem !important;
    }
    .pb-md-10 {
      padding-bottom: 12.5rem !important;
    }
    .pl-md-10 {
      padding-left: 12.5rem !important;
    }
    .px-md-10 {
      padding-left: 12.5rem !important;
      padding-right: 12.5rem !important;
    }
    .py-md-10 {
      padding-top: 12.5rem !important;
      padding-bottom: 12.5rem !important;
    }
    .p-md-11 {
      padding: 15rem !important;
    }
    .pt-md-11 {
      padding-top: 15rem !important;
    }
    .pr-md-11 {
      padding-right: 15rem !important;
    }
    .pb-md-11 {
      padding-bottom: 15rem !important;
    }
    .pl-md-11 {
      padding-left: 15rem !important;
    }
    .px-md-11 {
      padding-left: 15rem !important;
      padding-right: 15rem !important;
    }
    .py-md-11 {
      padding-top: 15rem !important;
      padding-bottom: 15rem !important;
    }
  }

  @media (min-width: 62em) {
    .p-lg-0 {
      padding: 0 !important;
    }
    .pt-lg-0 {
      padding-top: 0 !important;
    }
    .pr-lg-0 {
      padding-right: 0 !important;
    }
    .pb-lg-0 {
      padding-bottom: 0 !important;
    }
    .pl-lg-0 {
      padding-left: 0 !important;
    }
    .px-lg-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .pt-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pr-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pb-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-lg-1 {
      padding-left: 0.25rem !important;
    }
    .px-lg-1 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
    .py-lg-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .pt-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pr-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pb-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-lg-2 {
      padding-left: 0.5rem !important;
    }
    .px-lg-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
    .py-lg-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 1rem !important;
    }
    .pt-lg-3 {
      padding-top: 1rem !important;
    }
    .pr-lg-3 {
      padding-right: 1rem !important;
    }
    .pb-lg-3 {
      padding-bottom: 1rem !important;
    }
    .pl-lg-3 {
      padding-left: 1rem !important;
    }
    .px-lg-3 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .py-lg-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .p-lg-4 {
      padding: 1.8rem !important;
    }
    .pt-lg-4 {
      padding-top: 1.8rem !important;
    }
    .pr-lg-4 {
      padding-right: 1.8rem !important;
    }
    .pb-lg-4 {
      padding-bottom: 1.8rem !important;
    }
    .pl-lg-4 {
      padding-left: 1.8rem !important;
    }
    .px-lg-4 {
      padding-left: 1.8rem !important;
      padding-right: 1.8rem !important;
    }
    .py-lg-4 {
      padding-top: 1.8rem !important;
      padding-bottom: 1.8rem !important;
    }
    .p-lg-5 {
      padding: 3rem !important;
    }
    .pt-lg-5 {
      padding-top: 3rem !important;
    }
    .pr-lg-5 {
      padding-right: 3rem !important;
    }
    .pb-lg-5 {
      padding-bottom: 3rem !important;
    }
    .pl-lg-5 {
      padding-left: 3rem !important;
    }
    .px-lg-5 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
    .py-lg-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .p-lg-6 {
      padding: 4rem !important;
    }
    .pt-lg-6 {
      padding-top: 4rem !important;
    }
    .pr-lg-6 {
      padding-right: 4rem !important;
    }
    .pb-lg-6 {
      padding-bottom: 4rem !important;
    }
    .pl-lg-6 {
      padding-left: 4rem !important;
    }
    .px-lg-6 {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
    .py-lg-6 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .p-lg-7 {
      padding: 5rem !important;
    }
    .pt-lg-7 {
      padding-top: 5rem !important;
    }
    .pr-lg-7 {
      padding-right: 5rem !important;
    }
    .pb-lg-7 {
      padding-bottom: 5rem !important;
    }
    .pl-lg-7 {
      padding-left: 5rem !important;
    }
    .px-lg-7 {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
    .py-lg-7 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .p-lg-8 {
      padding: 7.5rem !important;
    }
    .pt-lg-8 {
      padding-top: 7.5rem !important;
    }
    .pr-lg-8 {
      padding-right: 7.5rem !important;
    }
    .pb-lg-8 {
      padding-bottom: 7.5rem !important;
    }
    .pl-lg-8 {
      padding-left: 7.5rem !important;
    }
    .px-lg-8 {
      padding-left: 7.5rem !important;
      padding-right: 7.5rem !important;
    }
    .py-lg-8 {
      padding-top: 7.5rem !important;
      padding-bottom: 7.5rem !important;
    }
    .p-lg-9 {
      padding: 10rem !important;
    }
    .pt-lg-9 {
      padding-top: 10rem !important;
    }
    .pr-lg-9 {
      padding-right: 10rem !important;
    }
    .pb-lg-9 {
      padding-bottom: 10rem !important;
    }
    .pl-lg-9 {
      padding-left: 10rem !important;
    }
    .px-lg-9 {
      padding-left: 10rem !important;
      padding-right: 10rem !important;
    }
    .py-lg-9 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .p-lg-10 {
      padding: 12.5rem !important;
    }
    .pt-lg-10 {
      padding-top: 12.5rem !important;
    }
    .pr-lg-10 {
      padding-right: 12.5rem !important;
    }
    .pb-lg-10 {
      padding-bottom: 12.5rem !important;
    }
    .pl-lg-10 {
      padding-left: 12.5rem !important;
    }
    .px-lg-10 {
      padding-left: 12.5rem !important;
      padding-right: 12.5rem !important;
    }
    .py-lg-10 {
      padding-top: 12.5rem !important;
      padding-bottom: 12.5rem !important;
    }
    .p-lg-11 {
      padding: 15rem !important;
    }
    .pt-lg-11 {
      padding-top: 15rem !important;
    }
    .pr-lg-11 {
      padding-right: 15rem !important;
    }
    .pb-lg-11 {
      padding-bottom: 15rem !important;
    }
    .pl-lg-11 {
      padding-left: 15rem !important;
    }
    .px-lg-11 {
      padding-left: 15rem !important;
      padding-right: 15rem !important;
    }
    .py-lg-11 {
      padding-top: 15rem !important;
      padding-bottom: 15rem !important;
    }
  }

  @media (min-width: 75em) {
    .p-xl-0 {
      padding: 0 !important;
    }
    .pt-xl-0 {
      padding-top: 0 !important;
    }
    .pr-xl-0 {
      padding-right: 0 !important;
    }
    .pb-xl-0 {
      padding-bottom: 0 !important;
    }
    .pl-xl-0 {
      padding-left: 0 !important;
    }
    .px-xl-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .pt-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pr-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pb-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-xl-1 {
      padding-left: 0.25rem !important;
    }
    .px-xl-1 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
    .py-xl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .pt-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pr-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pb-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-xl-2 {
      padding-left: 0.5rem !important;
    }
    .px-xl-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
    .py-xl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 1rem !important;
    }
    .pt-xl-3 {
      padding-top: 1rem !important;
    }
    .pr-xl-3 {
      padding-right: 1rem !important;
    }
    .pb-xl-3 {
      padding-bottom: 1rem !important;
    }
    .pl-xl-3 {
      padding-left: 1rem !important;
    }
    .px-xl-3 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .py-xl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .p-xl-4 {
      padding: 1.8rem !important;
    }
    .pt-xl-4 {
      padding-top: 1.8rem !important;
    }
    .pr-xl-4 {
      padding-right: 1.8rem !important;
    }
    .pb-xl-4 {
      padding-bottom: 1.8rem !important;
    }
    .pl-xl-4 {
      padding-left: 1.8rem !important;
    }
    .px-xl-4 {
      padding-left: 1.8rem !important;
      padding-right: 1.8rem !important;
    }
    .py-xl-4 {
      padding-top: 1.8rem !important;
      padding-bottom: 1.8rem !important;
    }
    .p-xl-5 {
      padding: 3rem !important;
    }
    .pt-xl-5 {
      padding-top: 3rem !important;
    }
    .pr-xl-5 {
      padding-right: 3rem !important;
    }
    .pb-xl-5 {
      padding-bottom: 3rem !important;
    }
    .pl-xl-5 {
      padding-left: 3rem !important;
    }
    .px-xl-5 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
    .py-xl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .p-xl-6 {
      padding: 4rem !important;
    }
    .pt-xl-6 {
      padding-top: 4rem !important;
    }
    .pr-xl-6 {
      padding-right: 4rem !important;
    }
    .pb-xl-6 {
      padding-bottom: 4rem !important;
    }
    .pl-xl-6 {
      padding-left: 4rem !important;
    }
    .px-xl-6 {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
    .py-xl-6 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .p-xl-7 {
      padding: 5rem !important;
    }
    .pt-xl-7 {
      padding-top: 5rem !important;
    }
    .pr-xl-7 {
      padding-right: 5rem !important;
    }
    .pb-xl-7 {
      padding-bottom: 5rem !important;
    }
    .pl-xl-7 {
      padding-left: 5rem !important;
    }
    .px-xl-7 {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
    .py-xl-7 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .p-xl-8 {
      padding: 7.5rem !important;
    }
    .pt-xl-8 {
      padding-top: 7.5rem !important;
    }
    .pr-xl-8 {
      padding-right: 7.5rem !important;
    }
    .pb-xl-8 {
      padding-bottom: 7.5rem !important;
    }
    .pl-xl-8 {
      padding-left: 7.5rem !important;
    }
    .px-xl-8 {
      padding-left: 7.5rem !important;
      padding-right: 7.5rem !important;
    }
    .py-xl-8 {
      padding-top: 7.5rem !important;
      padding-bottom: 7.5rem !important;
    }
    .p-xl-9 {
      padding: 10rem !important;
    }
    .pt-xl-9 {
      padding-top: 10rem !important;
    }
    .pr-xl-9 {
      padding-right: 10rem !important;
    }
    .pb-xl-9 {
      padding-bottom: 10rem !important;
    }
    .pl-xl-9 {
      padding-left: 10rem !important;
    }
    .px-xl-9 {
      padding-left: 10rem !important;
      padding-right: 10rem !important;
    }
    .py-xl-9 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .p-xl-10 {
      padding: 12.5rem !important;
    }
    .pt-xl-10 {
      padding-top: 12.5rem !important;
    }
    .pr-xl-10 {
      padding-right: 12.5rem !important;
    }
    .pb-xl-10 {
      padding-bottom: 12.5rem !important;
    }
    .pl-xl-10 {
      padding-left: 12.5rem !important;
    }
    .px-xl-10 {
      padding-left: 12.5rem !important;
      padding-right: 12.5rem !important;
    }
    .py-xl-10 {
      padding-top: 12.5rem !important;
      padding-bottom: 12.5rem !important;
    }
    .p-xl-11 {
      padding: 15rem !important;
    }
    .pt-xl-11 {
      padding-top: 15rem !important;
    }
    .pr-xl-11 {
      padding-right: 15rem !important;
    }
    .pb-xl-11 {
      padding-bottom: 15rem !important;
    }
    .pl-xl-11 {
      padding-left: 15rem !important;
    }
    .px-xl-11 {
      padding-left: 15rem !important;
      padding-right: 15rem !important;
    }
    .py-xl-11 {
      padding-top: 15rem !important;
      padding-bottom: 15rem !important;
    }
  }

  /*///////////////////////////////////
  // Miscellaneous
  ///////////////////////////////////*/
  [class^="icon-"], [class*=" icon-"] {
    display: inline-block;
  }

  .icon-position-fix {
    -webkit-transform: translateY(0.1em);
    -ms-transform: translateY(0.1em);
    transform: translateY(0.1em);
  }

  @media (min-width: 75em) {
    .ie .h-full {
      height: 100vh;
    }
    .ie .h-half {
      height: 50vh;
    }
  }

  .ie .background-holder video {
    width: 100%;
    height: auto;
  }

  .edge .background-holder video {
    width: 100%;
    height: auto;
  }

  ::-moz-selection {
    color: white;
    background-color: #007AFF;
  }

  ::selection {
    color: white;
    background-color: #007AFF;
  }

  #videoModal .embed-responsive {
    background-color: black;
  }

  .zopacity, *[data-zanim-trigger] {
    opacity: 0;
  }

  .puppeteer *[data-zanim-trigger] {
    opacity: 1 !important;
  }

  .ui.selection.dropdown .dropdown {
    right: 30px;
    position: absolute;
  }

  /*///////////////////////////////////
  // Prism
  ///////////////////////////////////*/
  :not(pre) > code[class*="language-"], pre[class*="language-"] {
    background-color: #fafafa;
    border: 1px solid #e1e1e1;
  }

  /*///////////////////////////////////
  // Hover Animation
  ///////////////////////////////////*/
  .btn[class*='hvr-'] {
    position: relative;
    overflow: hidden;
    z-index: 0;
  }

  .btn[class*='hvr-']:after, .btn[class*='hvr-sweep-collapse']:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition: -webkit-transform 0.25s ease-in-out;
    transition: -webkit-transform 0.25s ease-in-out;
    -o-transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  }

  .btn.hvr-sweep-left:after {
    -webkit-transform: translateX(-102%);
    -ms-transform: translateX(-102%);
    transform: translateX(-102%);
  }

  .btn.hvr-sweep-right:after {
    -webkit-transform: translateX(102%);
    -ms-transform: translateX(102%);
    transform: translateX(102%);
  }

  .btn.hvr-sweep-top:after {
    -webkit-transform: translateY(-102%);
    -ms-transform: translateY(-102%);
    transform: translateY(-102%);
  }

  .btn.hvr-sweep-bottom:after {
    -webkit-transform: translateY(102%);
    -ms-transform: translateY(102%);
    transform: translateY(102%);
  }

  .btn:active[class*='hvr-sweep-']:after, .btn:hover[class*='hvr-sweep-']:after, .btn:focus[class*='hvr-sweep-']:after, .btn:active:focus[class*='hvr-sweep-']:after {
    -webkit-transform: translateX(0) translateY(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }

  .btn.hvr-sweep-collapseY:before {
    -webkit-transform: translateY(-102%);
    -ms-transform: translateY(-102%);
    transform: translateY(-102%);
  }

  .btn.hvr-sweep-collapseY:after {
    -webkit-transform: translateY(102%);
    -ms-transform: translateY(102%);
    transform: translateY(102%);
  }

  .btn:active.hvr-sweep-collapseY:before, .btn:hover.hvr-sweep-collapseY:before, .btn:focus.hvr-sweep-collapseY:before, .btn:active:focus.hvr-sweep-collapseY:before {
    -webkit-transform: translateY(-49%);
    -ms-transform: translateY(-49%);
    transform: translateY(-49%);
  }

  .btn:active.hvr-sweep-collapseY:after, .btn:hover.hvr-sweep-collapseY:after, .btn:focus.hvr-sweep-collapseY:after, .btn:active:focus.hvr-sweep-collapseY:after {
    -webkit-transform: translateY(49%);
    -ms-transform: translateY(49%);
    transform: translateY(49%);
  }

  .btn.hvr-sweep-collapseX:before {
    -webkit-transform: translateX(-102%);
    -ms-transform: translateX(-102%);
    transform: translateX(-102%);
  }

  .btn.hvr-sweep-collapseX:after {
    -webkit-transform: translateX(102%);
    -ms-transform: translateX(102%);
    transform: translateX(102%);
  }

  .btn:active.hvr-sweep-collapseX:before, .btn:hover.hvr-sweep-collapseX:before, .btn:focus.hvr-sweep-collapseX:before, .btn:active:focus.hvr-sweep-collapseX:before {
    -webkit-transform: translateX(-49%);
    -ms-transform: translateX(-49%);
    transform: translateX(-49%);
  }

  .btn:active.hvr-sweep-collapseX:after, .btn:hover.hvr-sweep-collapseX:after, .btn:focus.hvr-sweep-collapseX:after, .btn:active:focus.hvr-sweep-collapseX:after {
    -webkit-transform: translateX(49%);
    -ms-transform: translateX(49%);
    transform: translateX(49%);
  }

  .btn.hvr-sweep-link:after {
    height: 2px;
    top: auto;
    -webkit-transform: translateX(-102%);
    -ms-transform: translateX(-102%);
    transform: translateX(-102%);
    background-color: #007AFF;
  }

  .btn:active.hvr-sweep-link, .btn:hover.hvr-sweep-link, .btn:focus.hvr-sweep-link, .btn:active:focus.hvr-sweep-link {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .btn:active.hvr-sweep-link:after, .btn:hover.hvr-sweep-link:after, .btn:focus.hvr-sweep-link:after, .btn:active:focus.hvr-sweep-link:after {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  [class*='btn-outline-'][class*='hvr-']:active, [class*='btn-outline-'][class*='hvr-']:hover, [class*='btn-outline-'][class*='hvr-']:focus, [class*='btn-outline-'][class*='hvr-']:active:focus {
    background-color: transparent;
  }

  .btn-outline-dark[class*='hvr-']:after, .btn-outline-dark[class*='hvr-']:before {
    background-color: #141414;
  }

  .btn-outline-primary[class*='hvr-']:after, .btn-outline-primary[class*='hvr-']:before {
    background-color: #007AFF;
  }

  .btn-outline-info[class*='hvr-']:after, .btn-outline-info[class*='hvr-']:before {
    background-color: #5AC8FA;
  }

  .btn-outline-success[class*='hvr-']:after, .btn-outline-success[class*='hvr-']:before {
    background-color: #4CD964;
  }

  .btn-outline-warning[class*='hvr-']:after, .btn-outline-warning[class*='hvr-']:before {
    background-color: #FF9500;
  }

  .btn-outline-danger[class*='hvr-']:after, .btn-outline-danger[class*='hvr-']:before {
    background-color: #FF3B30;
  }

  .btn-outline-white[class*='hvr-']:after, .btn-outline-white[class*='hvr-']:before {
    background-color: white;
  }

  .btn-outline-twitter[class*='hvr-']:after, .btn-outline-twitter[class*='hvr-']:before {
    background-color: #1da1f2;
  }

  .hvr-underline-none:hover {
    text-decoration: none;
  }

  /*-----------------------------------------------
  |   Preloader
  -----------------------------------------------*/
  .preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10001;
    background-color: black;
    opacity: 1;
    -webkit-transition: opacity 0.8s cubic-bezier(0.77, 0, 0.18, 1);
    -o-transition: opacity 0.8s cubic-bezier(0.77, 0, 0.18, 1);
    transition: opacity 0.8s cubic-bezier(0.77, 0, 0.18, 1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 150px;
  }

  .preloader.loaded {
    opacity: 0;
  }

  .preloader-wrapper {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
  }

  .preloader-wrapper.small {
    width: 36px;
    height: 36px;
  }

  .preloader-wrapper.big {
    width: 64px;
    height: 64px;
  }

  .preloader-wrapper.active {
    -webkit-animation: container-rotate 1568ms linear infinite;
    animation: container-rotate 1568ms linear infinite;
  }

  @-webkit-keyframes container-rotate {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes container-rotate {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .spinner-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-color: #26a69a;
  }

  .spinner-white, .spinner-white-only {
    border-color: #fff;
  }

  .spinner-blue, .spinner-blue-only {
    border-color: #4285f4;
  }

  .spinner-red, .spinner-red-only {
    border-color: #db4437;
  }

  .spinner-yellow, .spinner-yellow-only {
    border-color: #f4b400;
  }

  .spinner-green, .spinner-green-only {
    border-color: #0f9d58;
  }

  .active .spinner-layer.spinner-white {
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, white-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, white-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }

  .active .spinner-layer.spinner-blue {
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }

  .active .spinner-layer.spinner-red {
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }

  .active .spinner-layer.spinner-yellow {
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }

  .active .spinner-layer.spinner-green {
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }

  .active .spinner-layer, .active .spinner-layer.spinner-white-only, .active .spinner-layer.spinner-blue-only, .active .spinner-layer.spinner-red-only, .active .spinner-layer.spinner-yellow-only, .active .spinner-layer.spinner-green-only {
    opacity: 1;
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }

  @-webkit-keyframes fill-unfill-rotate {
    12.5% {
      -webkit-transform: rotate(135deg);
    }
    25% {
      -webkit-transform: rotate(270deg);
    }
    37.5% {
      -webkit-transform: rotate(405deg);
    }
    50% {
      -webkit-transform: rotate(540deg);
    }
    62.5% {
      -webkit-transform: rotate(675deg);
    }
    75% {
      -webkit-transform: rotate(810deg);
    }
    87.5% {
      -webkit-transform: rotate(945deg);
    }
    to {
      -webkit-transform: rotate(1080deg);
    }
  }

  @keyframes fill-unfill-rotate {
    12.5% {
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
    25% {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
    }
    37.5% {
      -webkit-transform: rotate(405deg);
      transform: rotate(405deg);
    }
    50% {
      -webkit-transform: rotate(540deg);
      transform: rotate(540deg);
    }
    62.5% {
      -webkit-transform: rotate(675deg);
      transform: rotate(675deg);
    }
    75% {
      -webkit-transform: rotate(810deg);
      transform: rotate(810deg);
    }
    87.5% {
      -webkit-transform: rotate(945deg);
      transform: rotate(945deg);
    }
    to {
      -webkit-transform: rotate(1080deg);
      transform: rotate(1080deg);
    }
  }

  @-webkit-keyframes blue-fade-in-out {
    from {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    26% {
      opacity: 0;
    }
    89% {
      opacity: 0;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes blue-fade-in-out {
    from {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    26% {
      opacity: 0;
    }
    89% {
      opacity: 0;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes white-fade-in-out {
    from {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51% {
      opacity: 0;
    }
  }

  @-webkit-keyframes red-fade-in-out {
    from {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51% {
      opacity: 0;
    }
  }

  @keyframes red-fade-in-out {
    from {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51% {
      opacity: 0;
    }
  }

  @-webkit-keyframes yellow-fade-in-out {
    from {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    76% {
      opacity: 0;
    }
  }

  @keyframes yellow-fade-in-out {
    from {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    76% {
      opacity: 0;
    }
  }

  @-webkit-keyframes green-fade-in-out {
    from {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes green-fade-in-out {
    from {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .gap-patch {
    position: absolute;
    top: 0;
    left: 45%;
    width: 10%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
  }

  .gap-patch .circle {
    width: 1000%;
    left: -450%;
  }

  .circle-clipper {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
  }

  .circle-clipper.left {
    float: left !important;
  }

  .circle-clipper.right {
    float: right !important;
  }

  .circle-clipper .circle {
    width: 200%;
    height: 100%;
    border-width: 3px;
    border-style: solid;
    border-color: inherit;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: none;
    animation: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .circle-clipper.left .circle {
    left: 0;
    border-right-color: transparent !important;
    -webkit-transform: rotate(129deg);
    -ms-transform: rotate(129deg);
    transform: rotate(129deg);
  }

  .circle-clipper.right .circle {
    left: -100%;
    border-left-color: transparent !important;
    -webkit-transform: rotate(-129deg);
    -ms-transform: rotate(-129deg);
    transform: rotate(-129deg);
  }

  .active .circle-clipper.left .circle {
    -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }

  .active .circle-clipper.right .circle {
    -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }

  @-webkit-keyframes left-spin {
    from {
      -webkit-transform: rotate(130deg);
    }
    50% {
      -webkit-transform: rotate(-5deg);
    }
    to {
      -webkit-transform: rotate(130deg);
    }
  }

  @keyframes left-spin {
    from {
      -webkit-transform: rotate(130deg);
      transform: rotate(130deg);
    }
    50% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    to {
      -webkit-transform: rotate(130deg);
      transform: rotate(130deg);
    }
  }

  @-webkit-keyframes right-spin {
    from {
      -webkit-transform: rotate(-130deg);
    }
    50% {
      -webkit-transform: rotate(5deg);
    }
    to {
      -webkit-transform: rotate(-130deg);
    }
  }

  @keyframes right-spin {
    from {
      -webkit-transform: rotate(-130deg);
      transform: rotate(-130deg);
    }
    50% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    to {
      -webkit-transform: rotate(-130deg);
      transform: rotate(-130deg);
    }
  }

  #spinnerContainer.cooldown {
    -webkit-animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
    animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  @-webkit-keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  h1, h2, h3, h4 {
    font-weight: 400;
  }

  h5 {
    font-weight: 600;
  }

  h6 {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.06em;
  }

  p {
    font-weight: 400;
    color: #6a6a6a;
  }

  p.lead {
    font-weight: 400;
  }

  a:not(.btn-icon-pop) .fa {
    -webkit-transform: translateY(0.01em);
    -ms-transform: translateY(0.01em);
    transform: translateY(0.01em);
  }

  .hvr-background-primary {
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .hvr-background-primary:active, .hvr-background-primary:hover, .hvr-background-primary:focus, .hvr-background-primary:active:focus {
    background-color: #007AFF !important;
  }

  .btn {
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-weight: 700;
  }

  .btn[class*="btn-outline-"]:not(.border-2x) {
    font-weight: 600;
  }

  .btn.btn-link {
    font-weight: 400 !important;
  }

  .btn.btn-capsule.btn-icon span {
    text-indent: 4px;
    width: 2.9rem;
  }

  .btn-outline-light {
    border-color: rgba(255, 255, 255, 0.35);
    color: rgba(255, 255, 255, 0.8);
    font-weight: 700 !important;
  }

  .border-color-mild {
    border-color: rgba(127, 127, 127, 0.35);
  }

  .background-holder.overlay-white:before {
    background: rgba(255, 255, 255, 0.5);
  }

  .no-circle {
    position: relative;
  }

  .no-circle > span {
    position: absolute;
    padding: 0.5rem;
    text-align: center;
    color: white;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    top: 0;
    left: -20px;
    font-weight: 700;
  }

  @media (min-width: 62em) {
    .border-right-radius-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .border-left-radius-0 {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .slick-line {
    height: 2px;
    width: 0;
  }

  .overflow-scroll {
    overflow: scroll;
  }

  .sticky-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .form-control {
    font-size: 0.8rem;
  }

  .input-group .input-group-addon {
    border: 1px solid #e1e1e1;
  }

  .input-group input:active, .input-group input:hover, .input-group input:focus, .input-group input:active:focus {
    border-color: #e1e1e1;
  }

  .input-capsule {
    border-radius: 50px;
  }

  .input-group-capsule .input-group-addon {
    border-radius: 50px 0 0 50px;
    border-width: 1px 0 1px 1px;
  }

  .input-group-capsule .input-group-addon + input {
    border-radius: 0 50px 50px 0;
  }

  .input-group-capsule input {
    border-radius: 50px 0 0 50px;
  }

  .input-group-capsule input + .input-group-addon {
    border-radius: 0 50px 50px 0;
    border-width: 1px 1px 1px 0;
  }

  .container-fluid .card-columns, .container-wide .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
  }

  @media (min-width: 48em) {
    .container-fluid .card-columns, .container-wide .card-columns {
      -webkit-column-count: 3;
      column-count: 3;
    }
  }

  @media (min-width: 75em) {
    .container-fluid .card-columns, .container-wide .card-columns {
      -webkit-column-count: 4;
      column-count: 4;
    }
  }

  .card-columns.sitemap {
    -webkit-column-count: 1;
    column-count: 1;
  }

  @media (min-width: 36em) {
    .card-columns.sitemap {
      -webkit-column-count: 2;
      column-count: 2;
    }
  }

  @media (min-width: 48em) {
    .card-columns.sitemap {
      -webkit-column-count: 3;
      column-count: 3;
    }
  }

  @media (min-width: 75em) {
    .card-columns.sitemap {
      -webkit-column-count: 4;
      column-count: 4;
    }
  }

  .pink {
    background-color: pink;
    height: 500px;
  }

  .white {
    background-image: url(https://d29fhpw069ctt2.cloudfront.net/photo/17003/preview/photo-1476433701303-4608fe1b1a3c_npreviews_eb97.jpg);
    background-size: cover;
    background-position: center;
    height: 500px;
  }

  .background-gradient-signup {
    background: -webkit-linear-gradient(45deg, #ff233d 1%, #f8253c 17%, #ff353f 32%, #ff4d40 68%, #ff5b41 83%, #ff5e41 100%);
    background: -o-linear-gradient(45deg, #ff233d 1%, #f8253c 17%, #ff353f 32%, #ff4d40 68%, #ff5b41 83%, #ff5e41 100%);
    background: linear-gradient(45deg, #ff233d 1%, #f8253c 17%, #ff353f 32%, #ff4d40 68%, #ff5b41 83%, #ff5e41 100%);
  }

  .pos-absolute.absolute-centered {
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }

  /*-----------------------------------------------
  |   Nav burger Panel
  -----------------------------------------------*/
  body {
    overflow-x: hidden;
  }

  .nav-burger-panel {
    border-top: 1px solid #2b2b2b;
    padding: 3rem;
    position: fixed;
    left: 100%;
    width: 500px;
    max-width: 100%;
    background-color: #141414;
    overflow-y: auto;
    z-index: 9999;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }

  .nav-burger-panel.nav-burger-panel-collapsed {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .znav-container .hamburger-inner, .znav-container .hamburger-inner:after, .znav-container .hamburger-inner:before {
    background-color: white !important;
  }

  .znav-container .is-active .hamburger-inner {
    background-color: transparent !important;
  }

  .znav-white .hamburger-inner, .znav-white .hamburger-inner:after, .znav-white .hamburger-inner:before {
    background-color: black !important;
  }

  /*-----------------------------------------------
  |   Icon with input
  -----------------------------------------------*/
  .input-group-icon {
    position: relative;
  }

  .input-group-icon .input-icon {
    position: absolute;
    z-index: 99;
    right: 1rem;
    font-size: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .input-group-icon .form-control {
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 3rem;
  }

  /*-----------------------------------------------
  |   Semantic UI Dropdown
  -----------------------------------------------*/
  .ui.selection.dropdown.border-2x .menu.border-2x {
    margin: 0 -2px;
    min-width: calc(100% + 4px);
  }

  .ui.selection.dropdown > .search.icon:before, .ui.selection.dropdown > .delete.icon:before, .ui.selection.dropdown > .dropdown.icon:before {
    content: '';
    height: 10px;
    width: 10px;
    border-right: 2px solid #949494;
    border-bottom: 2px solid #949494;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform 0.1s ease-in-out;
    transition: -webkit-transform 0.1s ease-in-out;
    -o-transition: transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    display: block;
  }

  .ui.selection.dropdown.active.visible > .search.icon:before, .ui.selection.dropdown.active.visible > .delete.icon:before, .ui.selection.dropdown.active.visible > .dropdown.icon:before {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

  /*-----------------------------------------------
  |   Showcase
  -----------------------------------------------*/
  .background-gradient {
    position: relative;
    background-image: -webkit-linear-gradient(315deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    background-image: -o-linear-gradient(315deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    background-image: linear-gradient(135deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    -webkit-animation: Gradient 15s ease infinite;
    animation: Gradient 15s ease infinite;
    background-size: 400% 400%;
  }

  @media (min-width: 87.5em) {
    .container-wide {
      max-width: 1340px;
    }
  }

  @media (min-width: 62em) {
    .znav-container .container-wide ul li a:not(.btn) {
      padding: 1rem 0.6rem;
    }
    .znav-container .container-wide .has-megamenu > a:after, .znav-container .container-wide .has-dropdown > a:after {
      top: 18px;
    }
    .znav-container .has-megamenu > a:after, .znav-container .has-dropdown > a:after {
      top: 8px;
    }
    .znav-container .has-megamenu > a, .znav-container .has-dropdown > a {
      padding-right: 1rem !important;
    }
  }

  .znav-container .megamenu a:not(.btn) {
    padding: 2px !important;
  }

  .showcase-content {
    margin-top: -28rem;
  }

  .showcase-content.showcase-pages {
    margin-top: -24rem;
  }

  .showcase-banner {
    padding-bottom: 27rem;
    max-width: 52rem;
  }

  .filter-item {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    -webkit-transition-property: -webkit-transform !important;
    transition-property: -webkit-transform !important;
    -o-transition-property: transform !important;
    transition-property: transform !important;
    transition-property: transform, -webkit-transform !important;
  }

  /*-----------------------------------------------
  |  TODO: Push this keyframe and remove from css
  -----------------------------------------------*/
  @-webkit-keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  /*-----------------------------------------------
  |   Showcase Menu
  -----------------------------------------------*/
  .showcase-megamenu {
    padding: 3rem 4rem 4rem;
    background: url(./../images/shapes/corner-2.png) no-repeat bottom left;
  }

  .ie .showcase-megamenu, .edge .showcase-megamenu {
    width: 100%;
  }

  @media (min-width: 62em) {
    .ie .showcase-megamenu, .edge .showcase-megamenu {
      max-width: 30rem;
    }
  }

  /*-----------------------------------------------
  |   IE fix
  -----------------------------------------------*/
  .wave {
    bottom: -2px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .wave {
      bottom: -20px;
    }
  }

  /*-----------------------------------------------
  |   Component navigation
  -----------------------------------------------*/
  .components-nav a {
    cursor: pointer;
  }
