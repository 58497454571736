html {
    height: 100vh;
    overflow: hidden;
}

body {
    margin: 0;
    height: 100vh;
    font-family: 'Quicksand';
}

#App {
    height: 100vh;
}

.sidebarContainer {
    float: left;
    width: 15%;
    height: 100vh;
}

.sidebar {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    overflow: -webkit-paged-x;
    width: 140px;
    border-right: 1px solid #f3f3f3;
}

.sidebar ul {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    float: left;
    width: 100%;
}

.sidebar ul li a {
    padding: 0px;
    width: 100%;
    display: block;
    text-align: center;
    color: #000;
    text-decoration: none;
    padding: 5px 0;
    margin-bottom: 5px;
}

.sidebar-content {
    height: 100vh;
}

.active-account {
    text-align: center;
}

.homeContainer {
    float: left;
    width: 100%;
}

.homeH1 {
    text-align: center;
    color: red;
}

.homeTitleH3 {
    text-align: center;
    font-size: 20px;
}

.callToActionContainer {
    overflow: auto;
    max-width: 100%;
    float: left;
    margin-top: 10px;
    padding: 0 70px;
}

.cardWordsButton {
    border: none;
    color: #000;
    background: #fff;
    outline: none;
    cursor: pointer;
}

.callToActionContainer div:last-child {
    margin-right: 0;
}

.bgRed {
    background: #ff0000;
}

.bgBlue {
    background: #0c88cf;
}

.bgGreen {
    background: #6da300;
}

.callToActionCard {
    text-align: center;
    border: 1px solid #f3f3f3;
    padding: 2%;
    float: left;
    width: 27.2%;
    margin: 1%;
    border-radius: 8px;
    color: white;
    font-size: 1.24em;
}

.callToActionCard p {
    font-size: 16px;
    font-weight: bold;
}

.cardImage {
    width: 70px;
    border-radius: 50%;
    height: 70px;
    margin: 20px auto auto;
    background: white;
}

.btn, button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-danger {
    color: #000;
    background-color: #fff;
    border-color: #fff;
    padding: 7px;
    border-radius: 4px;
    text-decoration: none;
    margin: 20px auto 0 auto;
    display: block;
    width: 40%;
}

.upgradeImgContainer {
    padding: 25px 200px;
    display: block;
    float: left;
    margin-top: 20px;
}

.upgradeImage {
    max-width: 100%;
}

.box-enter {
    animation: slide-in-elliptic-top-fwd 290ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.box-enter-active {
    opacity: 1;
}

.box-exit {
    animation: slide-out-blurred-top 290ms cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    opacity: 1;
}

.box-exit-active {
    opacity: 0;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-5-8 20:16:29
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */

@-webkit-keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}

@keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-5-8 20:19:3
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-blurred-top
 * ----------------------------------------
 */

@-webkit-keyframes slide-out-blurred-top {
    0% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
        transform: translateY(-1000px) scaleY(2) scaleX(0.2);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}

@keyframes slide-out-blurred-top {
    0% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
        transform: translateY(-1000px) scaleY(2) scaleX(0.2);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}

img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

@font-face {
    font-family: 'Quicksand';
    /* src: local('Quicksand'), url(../fonts/Quicksand-Medium.ttf) format('woff'); */
}

.slide-in-blurred-bottom {
    -webkit-animation: slide-in-blurred-bottom 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-bottom 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-5-11 16:27:31
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */

@-webkit-keyframes slide-in-blurred-bottom {
    0% {
        -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slide-in-blurred-bottom {
    0% {
        -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-5-11 17:7:7
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-blurred-bottom
 * ----------------------------------------
 */

@-webkit-keyframes slide-out-blurred-bottom {
    0% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(1000px) scaleY(2) scaleX(0.2);
        transform: translateY(1000px) scaleY(2) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}

@keyframes slide-out-blurred-bottom {
    0% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(1000px) scaleY(2) scaleX(0.2);
        transform: translateY(1000px) scaleY(2) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}

.cardContainer {
    border: 1px solid #858585;
    width: 100%;
    height: 50px;
    margin-right: 15px;
    margin-top: 10px;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* opacity: .2; */
    padding: 2px;
}
.MuiBottomNavigationAction-root {
    background-color: red!important;
    color: white!important;
    border-radius: 8px!important;
    margin-right: 10px!important;
}

.on, .off {
    background: gray;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    position: absolute;
    top: 2px;
    right: 2px;
    overflow: hidden;
    opacity: .3;
}

.on {
    background: rgb(0, 255, 0);
    opacity: 1;
}

.filterWrap-enter {
    animation: slide-in-blurred-bottom 290ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.filterWrap-enter-active {
    opacity: 1;
}

.filterWrap-exit {
    animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    opacity: 1;
}

.filterWrap-exit-active {
    opacity: 0;
}

input:focus, button:focus {
    border: yellow;
    outline: none;
}
a {
    color: #0c88cf;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-2-3 14:56:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-2-ver-left-2
 * ----------------------------------------
 */
  @keyframes flip-2-ver-left-2 {
    0% {
        transform: translateX(60%) rotateY(-90deg);
        transform-origin: 10% 100%;
        opacity: .5;
    }
    100% {
        transform: translateX(0) rotateY(0);
        transform-origin: 0% 100%;
        opacity: 1;
    }
  }


.flip-2-ver-left-2 {
    animation: flip-2-ver-left-2 .5s cubic-bezier(0.99, 0.99, 0.99, 0.99) both;
}