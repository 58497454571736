.eiyPower {
    background: url('https://sandbox.drummerboyhosting.com/lainies/cards/ce/ce.jpg') no-repeat center center;
    background-size: 20%;
    background-position: 2%;
    opacity: 1;
}

.lionGrowling {
    background: url('https://sandbox.drummerboyhosting.com/lainies/cards/ir/ir.jpg') no-repeat center center;
    background-size: 20%;
    background-position: 2%;
    opacity: 1;
}

.owlSound {
    background: url('https://sandbox.drummerboyhosting.com/lainies/cards/oo/oo.jpg') no-repeat center center;
    background-size: 20%;
    background-position: 2%;
    opacity: 1;
}

.lincolnHatBestFriends {
    background: url('https://sandbox.drummerboyhosting.com/lainies/cards/ee/ee.jpg') no-repeat center center;
    background-size: 20%;
    background-position: 2%;
    opacity: 1;
}

.oddBalls {
    background: url('https://sandbox.drummerboyhosting.com/lainies/cards/ar/ar.jpg') no-repeat center center;
    background-size: 20%;
    background-position: 2%;
    opacity: 1;
}

.marriedCouples {
    background: url('https://sandbox.drummerboyhosting.com/lainies/cards/ch/ch.jpg') no-repeat center center;
    background-size: 20%;
    background-position: 2%;
    opacity: 1;
}
.singingGirl {
    background: url('https://sandbox.drummerboyhosting.com/lainies/cards/au/au.jpg') no-repeat center center;
    background-size: 20%;
    background-position: 2%;
    opacity: 1;
}